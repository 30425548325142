/* Cloud Service Page Styles - English Version */
.cloud-page-en {
  direction: ltr;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  padding: 40px 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #2c3e50;
}

.cloud-page-en .cloud-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.cloud-page-en h1 {
  font-size: 3rem;
  background: linear-gradient(135deg, #1a237e 0%, #3949ab 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.4;
  font-weight: 800;
}

.cloud-page-en h2 {
  font-size: 2.2rem;
  color: #1a237e;
  margin: 3rem 0 2rem;
  text-align: center;
  font-weight: 700;
  position: relative;
}

.cloud-page-en h2:after {
  content: '';
  display: block;
  width: 60px;
  height: 4px;
  background: linear-gradient(135deg, #3949ab 0%, #5c6bc0 100%);
  margin: 1rem auto;
  border-radius: 2px;
}

.cloud-page-en h3 {
  font-size: 1.5rem;
  color: #303f9f;
  margin: 1.5rem 0 1rem;
  font-weight: 600;
}

.cloud-page-en p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #34495e;
  margin-bottom: 1.2rem;
}

/* Intro Section */
.cloud-page-en .cloud-intro {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 2rem 0;
  border: 1px solid rgba(63, 81, 181, 0.1);
  backdrop-filter: blur(10px);
}

/* Benefits Grid */
.cloud-page-en .benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.cloud-page-en .benefit-item {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(63, 81, 181, 0.1);
  position: relative;
  overflow: hidden;
}

.cloud-page-en .benefit-item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(135deg, #3949ab 0%, #5c6bc0 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.cloud-page-en .benefit-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .benefit-item:hover:before {
  opacity: 1;
}

/* Suitable Section */
.cloud-page-en .cloud-suitable {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 3rem 0;
  border: 1px solid rgba(63, 81, 181, 0.1);
}

.cloud-page-en .cloud-suitable ul {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.cloud-page-en .cloud-suitable li {
  position: relative;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.cloud-page-en .cloud-suitable li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #3949ab;
  font-weight: bold;
  background: rgba(57, 73, 171, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

/* Process Steps */
.cloud-page-en .process-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.cloud-page-en .step {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  border: 1px solid rgba(63, 81, 181, 0.1);
  transition: all 0.3s ease;
}

.cloud-page-en .step:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .step h3 {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.cloud-page-en .step h3:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 3px;
  background: linear-gradient(135deg, #3949ab 0%, #5c6bc0 100%);
  margin-right: 1rem;
  border-radius: 2px;
}

/* Why Us Section */
.cloud-page-en .cloud-why-us {
  background: rgba(255, 255, 255, 0.9);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  margin: 3rem 0;
  border: 1px solid rgba(63, 81, 181, 0.1);
}

.cloud-page-en .cloud-why-us ul {
  list-style: none;
  padding: 0;
  margin: 2rem 0;
}

.cloud-page-en .cloud-why-us li {
  position: relative;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1.1rem;
  color: #34495e;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.cloud-page-en .cloud-why-us li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #3949ab;
  font-weight: bold;
  background: rgba(57, 73, 171, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

/* CTA Section */
.cloud-page-en .cloud-cta {
  text-align: center;
  background: linear-gradient(135deg, #1a237e, #3949ab);
  color: white;
  padding: 3rem;
  border-radius: 10px;
  margin: 3rem 0;
}

.cloud-page-en .cloud-cta h2,
.cloud-page-en .cloud-cta p {
  color: white;
}

.cloud-page-en .contact-button {
  display: inline-block;
  background: #fff;
  color: #1a237e;
  border: none;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 2rem;
  font-weight: bold;
  text-decoration: none;
}

.cloud-page-en .contact-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Closing Section */
.cloud-page-en .cloud-closing {
  text-align: center;
  font-size: 1.2rem;
  color: #1a237e;
  margin: 3rem 0;
  font-weight: 500;
}

/* Pricing Section */
.cloud-page-en .pricing-section {
  padding: 4rem 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e8eaf6 100%);
  border-radius: 20px;
  margin: 3rem 0;
  position: relative;
  overflow: hidden;
}

.cloud-page-en .pricing-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(135deg, rgba(26, 35, 126, 0.05) 0%, rgba(57, 73, 171, 0.05) 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
}

.cloud-page-en .pricing-section h2 {
  position: relative;
  text-align: center;
  color: #1a237e;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: bold;
}

.cloud-page-en .pricing-plans-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
}

.cloud-page-en .deployment-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.cloud-page-en .info-box {
  text-align: center;
  padding: 1.8rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  flex: 1;
  min-width: 280px;
  max-width: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(63, 81, 181, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cloud-page-en .info-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .info-box i {
  font-size: 2rem;
  color: #3f51b5;
  margin-bottom: 1rem;
  background: rgba(63, 81, 181, 0.1);
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 1rem;
}

.cloud-page-en .info-box.highlight {
  background: linear-gradient(135deg, #e8eaf6 0%, #fff 100%);
  border: 2px solid #3f51b5;
  position: relative;
}

.cloud-page-en .info-box.highlight::before {
  content: '✨';
  position: absolute;
  top: -10px;
  right: -10px;
  background: #3f51b5;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.cloud-page-en .info-box p {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.cloud-page-en .info-box a {
  display: inline-block;
  color: #3f51b5;
  text-decoration: none;
  font-weight: 500;
  padding: 0.4rem 1rem;
  background: rgba(63, 81, 181, 0.08);
  border-radius: 20px;
  margin-top: 0.5rem;
  word-break: break-word;
  transition: all 0.3s ease;
}

.cloud-page-en .info-box a:hover {
  background: rgba(63, 81, 181, 0.15);
  transform: translateY(-2px);
}

.cloud-page-en .pricing-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  padding: 1rem;
}

.cloud-page-en .pricing-card {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  border: 1px solid rgba(63, 81, 181, 0.1);
  backdrop-filter: blur(10px);
}

.cloud-page-en .pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.cloud-page-en .pricing-card.popular {
  border: 3px solid #3f51b5;
  transform: scale(1.05);
  z-index: 1;
}

.cloud-page-en .popular-badge {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: linear-gradient(135deg, #3f51b5, #5c6bc0);
  color: white;
  padding: 0.6rem 1.2rem;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 4px 15px rgba(63, 81, 181, 0.3);
}

.cloud-page-en .pricing-card-header {
  padding: 2.5rem 2rem;
  background: linear-gradient(135deg, #1a237e, #3949ab);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cloud-page-en .pricing-card-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to right bottom, transparent 49%, white 51%);
}

.cloud-page-en .plan-name {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: white;
  font-weight: bold;
}

.cloud-page-en .price-container {
  margin: 1.5rem 0;
}

.cloud-page-en .price {
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .period {
  font-size: 1.1rem;
  opacity: 0.9;
}

.cloud-page-en .pricing-card-body {
  padding: 2.5rem 2rem;
}

.cloud-page-en .specs-list {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
}

.cloud-page-en .specs-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  font-size: 1.1rem;
  padding: 0.8rem;
  background: rgba(63, 81, 181, 0.05);
  border-radius: 10px;
  transition: transform 0.2s ease;
}

.cloud-page-en .specs-list li:hover {
  transform: translateX(-5px);
  background: rgba(63, 81, 181, 0.1);
}

.cloud-page-en .specs-list i {
  margin-right: 1.2rem;
  color: #3f51b5;
  font-size: 1.3rem;
  background: white;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .terms-disclaimer {
  text-align: center;
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(63, 81, 181, 0.05);
  border-radius: 10px;
  font-size: 0.9rem;
  color: #666;
}

.cloud-page-en .terms-disclaimer a {
  color: #3f51b5;
  text-decoration: none;
  font-weight: bold;
  margin-left: 0.3rem;
}

.cloud-page-en .terms-disclaimer a:hover {
  text-decoration: underline;
}

/* Enterprise Plan */
.cloud-page-en .custom-plan {
  grid-column: span 1;
  max-width: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.cloud-page-en .custom-plan:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
}

.cloud-page-en .custom-plan .pricing-card-header {
  background: linear-gradient(135deg, #263238, #455a64);
  padding: 2.5rem 2rem;
  text-align: center;
  position: relative;
}

.cloud-page-en .custom-plan .pricing-card-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to right bottom, transparent 49%, white 51%);
}

.cloud-page-en .custom-plan .pricing-card-body {
  padding: 2.5rem 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.cloud-page-en .custom-description {
  text-align: center;
  margin: 1rem 0 2rem;
  padding: 1.5rem;
  background: rgba(38, 50, 56, 0.05);
  border-radius: 15px;
}

.cloud-page-en .custom-description i {
  font-size: 2.5rem;
  color: #455a64;
  background: white;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .custom-description p {
  margin: 0;
  font-size: 1.1rem;
  color: #455a64;
  line-height: 1.6;
}

.cloud-page-en .enterprise-features {
  margin: 2rem 0;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.cloud-page-en .enterprise-feature {
  display: flex;
  align-items: flex-start;
  padding: 1.5rem;
  background: rgba(38, 50, 56, 0.05);
  border-radius: 15px;
  transition: transform 0.2s ease;
  height: 100%;
}

.cloud-page-en .enterprise-feature:hover {
  transform: translateX(-5px);
  background: rgba(38, 50, 56, 0.08);
}

.cloud-page-en .enterprise-feature i {
  font-size: 1.4rem;
  color: #455a64;
  margin-right: 1.2rem;
  background: white;
  min-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.cloud-page-en .enterprise-feature-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cloud-page-en .enterprise-feature-text h5 {
  margin: 0;
  font-size: 1.2rem;
  color: #263238;
  font-weight: 600;
}

.cloud-page-en .enterprise-feature-text p {
  margin: 0;
  font-size: 1rem;
  color: #546e7a;
  line-height: 1.6;
}

.cloud-page-en .contact-btn {
  width: 100%;
  padding: 1.2rem;
  background: linear-gradient(135deg, #455a64, #607d8b);
  border: none;
  border-radius: 50px;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  margin-top: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-decoration: none;
}

.cloud-page-en .contact-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #37474f, #546e7a);
}

.cloud-page-en .contact-btn i {
  font-size: 1.4rem;
}

/* Features Section */
.cloud-page-en .features-section {
  margin-top: 5rem;
  padding: 3rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.cloud-page-en .features-header {
  margin-bottom: 3rem;
  text-align: center;
}

.cloud-page-en .features-title {
  font-size: 2.2rem;
  color: #1a237e;
  margin-bottom: 1rem;
  font-weight: bold;
}

.cloud-page-en .features-subtitle {
  color: #666;
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.cloud-page-en .features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.cloud-page-en .feature-box {
  background: #fff;
  padding: 1.8rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 1.2rem;
  transition: transform 0.3s ease;
  border: 1px solid rgba(63, 81, 181, 0.1);
}

.cloud-page-en .feature-box:hover {
  transform: translateY(-5px);
}

.cloud-page-en .feature-icon {
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #e8eaf6, #c5cae9);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(63, 81, 181, 0.1);
}

.cloud-page-en .feature-icon i {
  color: #3f51b5;
  font-size: 1.4rem;
}

.cloud-page-en .feature-text {
  flex: 1;
  font-size: 1.1rem;
  color: #424242;
  font-weight: 500;
}

/* PayPal Button Container */
.cloud-page-en [id^="paypal-button-container"] {
  margin-top: 2rem;
  min-height: 45px;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .cloud-page-en .pricing-row {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
  }
  
  .cloud-page-en .custom-plan {
    grid-column: span 1;
  }

  .cloud-page-en .enterprise-features {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .cloud-page-en h1 {
    font-size: 2.2rem;
  }

  .cloud-page-en h2 {
    font-size: 1.8rem;
  }

  .cloud-page-en h3 {
    font-size: 1.3rem;
  }

  .cloud-page-en .benefits-grid {
    grid-template-columns: 1fr;
  }

  .cloud-page-en .process-steps {
    grid-template-columns: 1fr;
  }

  .cloud-page-en .cloud-cta {
    padding: 2rem;
  }
  
  .cloud-page-en .pricing-card.popular {
    transform: none;
  }
  
  .cloud-page-en .enterprise-features {
    grid-template-columns: 1fr;
  }
  
  .cloud-page-en .enterprise-feature {
    padding: 1.2rem;
  }
  
  .cloud-page-en .contact-btn {
    padding: 1rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .cloud-page-en .custom-description {
    padding: 1rem;
  }
  
  .cloud-page-en .custom-description i {
    width: 50px;
    height: 50px;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .cloud-page-en .enterprise-feature {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
  }
  
  .cloud-page-en .enterprise-feature i {
    margin: 0 0 1rem 0;
  }
  
  .cloud-page-en .enterprise-feature-text {
    text-align: center;
  }
  
  .cloud-page-en .enterprise-feature-text h5 {
    margin-bottom: 0.8rem;
  }
  
  .cloud-page-en .contact-btn {
    margin-top: 1.5rem;
    padding: 1rem;
    font-size: 1.1rem;
  }
}

/* Hero Section */
.cloud-page-en .hero-section {
  text-align: center;
  padding: 4rem 0;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, rgba(26, 35, 126, 0.03) 0%, rgba(57, 73, 171, 0.03) 100%);
  border-radius: 30px;
  margin-bottom: 4rem;
}

.cloud-page-en .hero-section:before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(57, 73, 171, 0.03) 0%, rgba(26, 35, 126, 0.02) 50%, transparent 100%);
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cloud-page-en .hero-section h1 {
  position: relative;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 0 2rem;
}

.cloud-page-en .hero-section .cloud-intro {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

/* Section Content */
.cloud-page-en .section-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

/* Benefits Grid */
.cloud-page-en .benefit-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cloud-page-en .benefit-content h3 {
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.cloud-page-en .benefit-content h3:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background: linear-gradient(135deg, #3949ab 0%, #5c6bc0 100%);
  border-radius: 2px;
}

/* Process Steps */
.cloud-page-en .process-steps {
  position: relative;
}

.cloud-page-en .process-steps:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, rgba(57, 73, 171, 0.1), transparent);
  z-index: 0;
}

.cloud-page-en .step {
  position: relative;
  z-index: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cloud-page-en .hero-section {
    padding: 3rem 1rem;
  }

  .cloud-page-en .hero-section h1 {
    padding: 0 1rem;
  }

  .cloud-page-en .process-steps:before {
    display: none;
  }
} 