/* Optimized Animation Effects */
@keyframes gradient-shift {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
}

@keyframes float {
    0%, 100% { 
        transform: translateY(0); 
        filter: drop-shadow(0 5px 15px rgba(0,0,0,0.1));
    }
    50% { 
        transform: translateY(-10px); 
        filter: drop-shadow(0 25px 25px rgba(0,0,0,0.1));
    }
}

@keyframes shine {
    to {
        background-position: 200% 50%;
    }
}

/* Optimized Text Gradient */
.text-gradient {
    background: linear-gradient(
        300deg,
        #4285F4,
        #34A853,
        #FBBC04,
        #EA4335
    );
    background-size: 200% auto;
    animation: gradient-shift 8s ease infinite;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    will-change: background-position;
}

/* Optimized Card Effects */
.service-card {
    transform-origin: center bottom;
    will-change: transform;
    backface-visibility: hidden;
}

.service-icon {
    transform-origin: center;
    will-change: transform;
}

/* Optimized Loading States */
.loading-skeleton {
    background: linear-gradient(
        90deg,
        #f0f0f0 25%,
        #e0e0e0 37%,
        #f0f0f0 63%
    );
    background-size: 400% 100%;
    animation: skeleton-loading 1.4s ease infinite;
    will-change: background-position;
}

@keyframes skeleton-loading {
    from {
        background-position: 100% 50%;
    }
    to {
        background-position: 0 50%;
    }
}

/* Optimized Hover Effects */
.hover-effect {
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform, opacity;
}

.hover-effect:hover {
    transform: translateY(-5px);
}

/* Optimized Badge Animation */
.animated-badge {
    position: relative;
    overflow: hidden;
}

.animated-badge::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        120deg,
        transparent,
        rgba(255,255,255,0.3),
        transparent
    );
    animation: shine 2s linear infinite;
    will-change: transform;
}

/* Optimized Link Animations */
.animated-link {
    position: relative;
    transition: color 0.2s ease;
}

.animated-link::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
    will-change: transform;
}

.animated-link:hover::after {
    transform: scaleX(1);
    transform-origin: left;
}

/* Optimized Carousel Effects */
.carousel-slide {
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
    will-change: opacity, transform;
}

.carousel-slide.active {
    opacity: 1;
    transform: translateX(0);
}

/* Performance Optimizations */
.gpu-accelerated {
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
} 