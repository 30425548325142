.tech-stack-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8fafc 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
}

.tech-stack-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at top right, rgba(37, 99, 235, 0.05) 0%, transparent 50%),
    radial-gradient(circle at bottom left, rgba(245, 158, 11, 0.05) 0%, transparent 50%);
  pointer-events: none;
}

.section-header {
  margin-bottom: 60px;
}

.section-title {
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 20px;
}

.section-title .gradient-text {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  background: linear-gradient(135deg, #2563eb, #3b82f6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 10px;
}

.section-title .main-title {
  display: block;
  color: #1e293b;
}

.section-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #64748b;
  max-width: 800px;
  margin: 0 auto;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  padding: 20px;
}

.tech-item {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 30px;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.tech-icon {
  font-size: 3rem;
  margin-bottom: 20px;
  transition: transform 0.4s ease;
}

.tech-item:hover .tech-icon {
  transform: scale(1.1);
}

.tech-content h3 {
  font-size: 1.4rem;
  font-weight: 700;
  color: #1e293b;
  margin-bottom: 10px;
}

.tech-content p {
  font-size: 1rem;
  color: #64748b;
  margin: 0;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 991px) {
  .tech-stack-section {
    padding: 80px 0;
  }

  .section-title {
    font-size: 2.4rem;
  }
}

@media (max-width: 768px) {
  .tech-stack-section {
    padding: 60px 0;
  }

  .section-title {
    font-size: 2rem;
  }

  .tech-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  .tech-item {
    padding: 25px;
  }
}

@media (max-width: 576px) {
  .section-title {
    font-size: 1.8rem;
  }

  .section-description {
    font-size: 1rem;
  }

  .tech-grid {
    grid-template-columns: 1fr;
    padding: 10px;
  }
} 