/* Modern Service Section Styles */
.service-section {
    padding: 8rem 0;
    background: 
        radial-gradient(circle at 0% 0%, rgba(66, 133, 244, 0.1) 0%, transparent 50%),
        radial-gradient(circle at 100% 100%, rgba(52, 168, 83, 0.1) 0%, transparent 50%);
    position: relative;
    overflow: hidden;
}

/* Floating Background Elements */
.service-section::before,
.service-section::after {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    filter: blur(80px);
    opacity: 0.1;
    animation: float 15s infinite ease-in-out;
}

.service-section::before {
    background: #4285F4;
    top: -200px;
    right: -200px;
    animation-delay: -5s;
}

.service-section::after {
    background: #34A853;
    bottom: -200px;
    left: -200px;
}

/* Modern Card Styles */
.service-card {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 24px;
    padding: 2.5rem;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 
        0 4px 20px rgba(0, 0, 0, 0.05),
        0 0 0 1px rgba(255, 255, 255, 0.1) inset;
}

.service-card:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.12),
        0 0 0 1px rgba(255, 255, 255, 0.15) inset;
}

/* Modern Icon Styles */
.service-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-bottom: 2rem;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.service-card:hover .service-icon {
    transform: scale(1.1) rotate(5deg);
}

.service-icon i {
    font-size: 1.8rem;
    background: linear-gradient(45deg, #4285F4, #34A853);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Enhanced Content Styles */
.service-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
    background: linear-gradient(45deg, #1a1a1a, #4a4a4a);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px;
}

.service-description {
    color: #666;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
}

/* Modern Carousel Styles */
.service-carousel {
    position: relative;
    padding: 2rem;
}

.carousel-image {
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.12),
        0 0 0 1px rgba(255, 255, 255, 0.1) inset;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
}

.carousel-image:hover {
    transform: scale(1.03);
}

.carousel-image img {
    transition: all 0.5s ease;
}

.carousel-image:hover img {
    transform: scale(1.1);
}

/* Enhanced Section Title */
.section-title {
    position: relative;
    margin-bottom: 4rem;
}

.section-title h2 {
    font-size: 2.8rem;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.section-title .badge {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
    border-radius: 50px;
    background: linear-gradient(135deg, rgba(66, 133, 244, 0.1), rgba(52, 168, 83, 0.1));
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #4285F4;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* Modern Button Styles */
.btn-link {
    position: relative;
    padding: 0.8rem 0;
    font-weight: 600;
    transition: all 0.3s ease;
    text-decoration: none;
}

.btn-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(45deg, #4285F4, #34A853);
    transition: width 0.3s ease;
}

.btn-link:hover::after {
    width: 100%;
}

.btn-link i {
    transition: transform 0.3s ease;
}

.btn-link:hover i {
    transform: translateX(5px);
}

/* Enhanced Animation Classes */
@keyframes float {
    0%, 100% { transform: translateY(0) rotate(0deg); }
    50% { transform: translateY(-20px) rotate(5deg); }
}

.fade-up {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-up.visible {
    opacity: 1;
    transform: translateY(0);
}

/* Modern Grid Layout */
.feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2.5rem;
    margin-top: 3rem;
}

/* Enhanced Responsive Design */
@media (max-width: 991.98px) {
    .service-section {
        padding: 5rem 0;
    }
    
    .section-title h2 {
        font-size: 2.2rem;
    }
    
    .feature-grid {
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        gap: 2rem;
    }
}

@media (max-width: 767.98px) {
    .service-card {
        padding: 2rem;
    }
    
    .service-icon {
        width: 60px;
        height: 60px;
    }
    
    .section-title h2 {
        font-size: 1.8rem;
    }
    
    .service-title {
        font-size: 1.3rem;
    }
}

/* Custom Scroll Animation */
.owl-carousel .owl-item {
    opacity: 0.5;
    transform: scale(0.9);
    transition: all 0.5s ease;
}

.owl-carousel .owl-item.active {
    opacity: 1;
    transform: scale(1);
}

/* Fancy Hover Effects */
.service-card::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(125deg, transparent 40%, rgba(255, 255, 255, 0.4));
    opacity: 0;
    transition: opacity 0.5s ease;
}

.service-card:hover::after {
    opacity: 1;
} 