/* Modern Hero Section */
.hero-section {
    min-height: 100vh;
    padding: 120px 0;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, #f8fafc 0%, #ffffff 100%);
}

/* Background Effects */
.hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: radial-gradient(circle at top right, rgba(97, 84, 255, 0.05) 0%, transparent 50%),
                radial-gradient(circle at bottom left, rgba(0, 13, 255, 0.05) 0%, transparent 50%);
}

.gradient-sphere {
    position: absolute;
    border-radius: 50%;
    filter: blur(80px);
    opacity: 0.6;
    mix-blend-mode: multiply;
}

.gradient-sphere-1 {
    top: -10%;
    right: -5%;
    width: 500px;
    height: 500px;
    background: linear-gradient(45deg, rgba(97, 84, 255, 0.15), rgba(0, 13, 255, 0.15));
    animation: float 15s infinite alternate ease-in-out;
}

.gradient-sphere-2 {
    bottom: -15%;
    left: -10%;
    width: 600px;
    height: 600px;
    background: linear-gradient(45deg, rgba(0, 13, 255, 0.1), rgba(97, 84, 255, 0.1));
    animation: float 20s infinite alternate-reverse ease-in-out;
}

.gradient-sphere-3 {
    top: 40%;
    left: 30%;
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(97, 84, 255, 0.1) 0%, rgba(0, 13, 255, 0.1) 100%);
    animation: float 18s infinite alternate ease-in-out;
}

/* Hero Content */
.hero-content {
    position: relative;
    z-index: 1;
}

.hero-badge {
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    background: rgba(66, 133, 244, 0.1);
    border: 1px solid rgba(66, 133, 244, 0.2);
    border-radius: 50px;
    color: #4285F4;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    backdrop-filter: blur(10px);
}

.hero-title {
    font-size: 3.5rem;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-weight: 700;
}

.gradient-text {
    background: linear-gradient(45deg, #4285F4, #34A853);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #4285F4; /* Fallback color */
    display: block;
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.highlight-text {
    color: #202124;
    position: relative;
    display: inline-block;
}

.hero-description {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #5f6368;
    margin-bottom: 2.5rem;
    max-width: 600px;
}

/* Feature Card */
.feature-card {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.feature-content h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
}

.feature-content p {
    color: #5f6368;
    margin: 0;
}

/* Buttons */
.hero-buttons {
    display: flex;
    gap: 1.2rem;
    margin-top: 2.5rem;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.hero-buttons .btn {
    padding: 1rem 1.8rem;
    font-weight: 600;
    font-size: 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    border: none;
    white-space: nowrap;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero-buttons .btn:first-child {
    background: linear-gradient(135deg, #4285F4, #34A853);
    color: white;
}

.hero-buttons .btn:nth-child(2) {
    background: linear-gradient(135deg, #A2FADD, #82d8bd);
    color: #0f172a;
}

.hero-buttons .btn:last-child {
    background: linear-gradient(135deg, #FBBC04, #EA4335);
    color: white;
}

.hero-buttons .btn i {
    font-size: 1.25rem;
    transition: transform 0.3s ease;
}

.hero-buttons .btn:hover i {
    transform: translateX(3px);
}

.hero-buttons .btn::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    opacity: 0;
    transition: opacity 0.3s ease;
}

.hero-buttons .btn:hover::before {
    opacity: 1;
}

/* Stats Section */
.hero-stats {
    display: flex;
    gap: 3rem;
    margin-top: 3rem;
}

.stat-item {
    text-align: center;
}

.stat-number {
    font-size: 2.5rem;
    font-weight: 700;
    background: linear-gradient(45deg, #4285F4, #34A853);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
}

.stat-label {
    color: #5f6368;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    display: block;
}

/* Image Section */
.hero-image-wrapper {
    position: relative;
    z-index: 1;
}

.hero-image {
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.floating {
    animation: float 6s ease-in-out infinite;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(66, 133, 244, 0.1), rgba(52, 168, 83, 0.1));
    mix-blend-mode: overlay;
}

/* Tech Bubbles */
.tech-bubble {
    position: absolute;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.tech-bubble-1 {
    top: 10%;
    right: 10%;
    color: #4285F4;
}

.tech-bubble-2 {
    bottom: 20%;
    right: 5%;
    color: #34A853;
}

.tech-bubble-3 {
    top: 50%;
    left: 0;
    color: #FBBC04;
}

/* Scroll Indicator */
.scroll-indicator {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #5f6368;
    font-size: 0.9rem;
    z-index: 2;
}

.scroll-indicator i {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 0.5rem;
}

/* Animations */
@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

@keyframes glow {
    0% {
        transform: rotate(45deg) translateX(-100%);
    }
    100% {
        transform: rotate(45deg) translateX(100%);
    }
}

/* Responsive Design */
@media (max-width: 991.98px) {
    .hero-section {
        padding: 80px 0;
        text-align: center;
    }
    .hero-title {
        font-size: 2.5rem;
    }
    .hero-image-wrapper {
        margin-top: 3rem;
    }
    .tech-bubble {
        display: none;
    }
    .hero-buttons {
        justify-content: center;
        gap: 1rem;
    }
    .hero-buttons .btn {
        padding: 0.9rem 1.6rem;
        font-size: 0.95rem;
    }
    .hero-stats {
        justify-content: center;
    }
}

@media (max-width: 767.98px) {
    .hero-section {
        padding: 60px 0;
    }
    .hero-title {
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    .hero-description {
        font-size: 1rem;
        margin-bottom: 2rem;
    }
    .hero-buttons {
        flex-direction: column;
        gap: 1rem;
        padding: 0 1rem;
        max-width: 100%;
    }
    .hero-buttons .btn {
        width: 100%;
        padding: 1rem;
        justify-content: space-between;
        border-radius: 10px;
    }
    .hero-buttons .btn i {
        font-size: 1.2rem;
    }
    .hero-stats {
        flex-direction: column;
        gap: 2rem;
    }
    .stat-number {
        font-size: 2rem;
    }
    .feature-card {
        padding: 1rem;
    }
}

/* iPhone SE and smaller devices */
@media (max-width: 375px) {
    .hero-section {
        padding: 40px 0;
    }
    .hero-title {
        font-size: 1.75rem;
    }
    .hero-badge {
        font-size: 0.8rem;
        padding: 6px 12px;
        margin-bottom: 1.5rem;
    }
    .hero-description {
        font-size: 0.9rem;
        line-height: 1.6;
    }
    .hero-buttons {
        margin-top: 2rem;
        gap: 0.8rem;
    }
    .hero-buttons .btn {
        padding: 0.9rem;
        font-size: 0.9rem;
    }
    .hero-buttons .btn i {
        font-size: 1.1rem;
    }
    .stat-item {
        width: 100%;
    }
    .feature-card {
        margin: 1rem 0;
    }
    .feature-icon {
        font-size: 1.5rem;
    }
    .feature-content h3 {
        font-size: 1.1rem;
    }
    .feature-content p {
        font-size: 0.9rem;
    }
    .gradient-sphere {
        opacity: 0.5;
    }
}

/* Performance Optimizations */
.gpu-accelerated {
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
} 