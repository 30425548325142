.global-deploy-button {
  position: fixed;
  top: 250px;
  left: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
  z-index: 999;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.global-deploy-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
  background-color: #0056b3;
}

.global-deploy-button i {
  font-size: 16px;
}

/* Modal Styles */
.deploy-modal .modal-content {
  border-radius: 12px;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.deploy-modal .modal-header {
  border-bottom: 1px solid #eee;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deploy-modal .modal-title {
  font-size: 24px;
  font-weight: 600;
}

.deploy-modal .language-switch {
  display: flex;
  gap: 8px;
  margin-left: auto;
  margin-right: 48px;
}

.deploy-modal .modal-body {
  padding: 30px;
}

/* Pricing Cards */
.pricing-plans-container {
  max-width: 1200px;
  margin: 0 auto;
}

.deployment-info {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.info-box {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  min-width: 250px;
}

.info-box.highlight {
  background-color: #e7f5ff;
  color: #0056b3;
}

.info-box i {
  font-size: 20px;
  color: #007bff;
}

.info-box p {
  margin: 0;
  font-size: 14px;
}

.pricing-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 24px;
  height: 100%;
  position: relative;
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.pricing-card.popular {
  border: 2px solid #007bff;
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 24px;
  background: #007bff;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
}

.pricing-card-header {
  text-align: center;
  margin-bottom: 24px;
}

.plan-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
}

.price {
  font-size: 32px;
  font-weight: 700;
  color: #007bff;
}

.period {
  color: #6c757d;
  font-size: 14px;
}

.specs-list {
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
}

.specs-list li {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
  font-size: 14px;
}

.specs-list i {
  color: #007bff;
  width: 16px;
}

/* Features Section */
.features-section {
  padding: 4rem 2rem;
  background: white;
  overflow: visible;
}

.features-header {
  text-align: center;
  margin-bottom: 3rem;
  background: none;
  overflow: visible;
  position: relative;
  z-index: 1;
}

.features-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2d3748;
  margin-bottom: 1rem;
  background: none;
  overflow: visible;
  position: relative;
  z-index: 2;
  width: 100%;
  height: auto;
  min-height: fit-content;
}

.features-subtitle {
  font-size: 1.2rem;
  color: #718096;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.feature-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.feature-icon-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(135deg, #667eea, #764ba2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.2rem;
}

.feature-icon-wrapper i {
  font-size: 1.5rem;
  color: white;
}

.feature-content {
  flex: 1;
}

.feature-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

/* RTL Support */
[dir="rtl"] .global-deploy-button {
  left: auto;
  right: 20px;
}

[dir="rtl"] .feature-text {
  text-align: right;
}

/* Responsive Design */
@media (max-width: 768px) {
  .global-deploy-button {
    top: auto;
    bottom: 80px;
    left: 20px;
    padding: 8px 16px;
    font-size: 13px;
  }

  .deploy-modal .modal-header {
    padding: 15px 20px;
  }

  .deploy-modal .modal-body {
    padding: 15px;
  }

  .deployment-info {
    gap: 10px;
    flex-direction: column;
  }

  .info-box {
    padding: 10px 15px;
    width: 100%;
    min-width: unset;
  }

  .pricing-row {
    margin: 0 -8px;
  }

  .pricing-row > [class*='col-'] {
    padding: 0 8px;
    margin-bottom: 16px;
  }

  .pricing-card {
    padding: 16px;
  }

  .plan-name {
    font-size: 18px;
  }

  .price {
    font-size: 28px;
  }

  .specs-list {
    margin-bottom: 16px;
  }

  .specs-list li {
    font-size: 13px;
    margin-bottom: 8px;
  }

  .features-section {
    padding: 2rem 1rem;
  }
  
  .features-title {
    font-size: 1.75rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }

  [dir="rtl"] .global-deploy-button {
    left: auto;
    right: 20px;
  }
}

/* Extra Small Devices (iPhone SE etc.) */
@media (max-width: 375px) {
  .global-deploy-button {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    justify-content: center;
    z-index: 1000;
  }

  .deploy-modal .modal-body {
    padding: 10px;
  }

  .pricing-row {
    margin: 0;
  }

  .pricing-row > [class*='col-'] {
    padding: 0;
    margin-bottom: 12px;
  }

  .pricing-card {
    padding: 12px;
    margin: 0 8px;
  }

  .plan-name {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .price {
    font-size: 24px;
  }

  .period {
    font-size: 12px;
  }

  .specs-list li {
    font-size: 12px;
    gap: 8px;
    margin-bottom: 6px;
  }

  .popular-badge {
    font-size: 10px;
    padding: 3px 8px;
    right: 16px;
  }

  .features-title {
    font-size: 1.5rem;
  }

  .features-subtitle {
    font-size: 1rem;
  }

  [dir="rtl"] .global-deploy-button {
    left: 50%;
    right: auto;
  }
} 