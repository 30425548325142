/* Modern styling for Consulting Solutions component */

:root {
    /* Primary Colors */
    --primary-color: #4780a1;
    --primary-dark: #4f46e5;
    --primary-light: #818cf8;
    
    /* Secondary Colors */
    --secondary-color: #06b6d4;
    --secondary-dark: #0891b2;
    --secondary-light: #22d3ee;
    
    /* Accent Colors */
    --accent-color: #f43f5e;
    --accent-dark: #e11d48;
    --accent-light: #fb7185;
    
    /* Complementary Colors */
    --success-color: #10b981;
    --warning-color: #f59e0b;
    --info-color: #3b82f6;
    
    /* Background Colors */
    --background-light: #f1f5f9;
    --background-dark: #0f172a;
    --background-gradient: linear-gradient(135deg, #f1f5f9 0%, #e2e8f0 100%);
    
    /* Text Colors */
    --text-primary: #1e293b;
    --text-secondary: #475569;
    --text-light: #94a3b8;
}

.consulting-solutions-offer {
    background: var(--background-gradient);
    position: relative;
    overflow: hidden;
}

.consulting-solutions-offer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(45deg, 
        rgba(99, 102, 241, 0.05) 0%, 
        rgba(6, 182, 212, 0.05) 50%,
        rgba(244, 63, 94, 0.05) 100%
    );
    z-index: 0;
}

.section-divider {
    width: 60px;
    height: 4px;
    background: linear-gradient(90deg, 
        var(--primary-color) 0%, 
        var(--secondary-color) 50%, 
        var(--accent-color) 100%
    );
    margin: 0 auto;
    border-radius: 2px;
}

.service-card {
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
}

.service-card:hover {
    transform: translateY(-8px);
}

.service-card:hover .service-content {
    box-shadow: 0 8px 25px rgba(99, 102, 241, 0.15);
}

.service-content {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px rgba(99, 102, 241, 0.08);
    height: 100%;
    transition: all 0.4s ease;
    padding: 2rem;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(99, 102, 241, 0.1);
}

.service-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg,
        rgba(99, 102, 241, 0.05) 0%,
        rgba(6, 182, 212, 0.05) 50%,
        rgba(244, 63, 94, 0.05) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
}

.service-content:hover::before {
    opacity: 1;
}

.service-icon {
    width: 64px;
    height: 64px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    position: relative;
    transition: all 0.4s ease;
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.service-icon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    filter: blur(15px);
    opacity: 0.4;
    z-index: -1;
    transform: translateY(10px) scale(0.95);
}

.service-icon i {
    color: #ffffff;
    font-size: 24px;
    transition: all 0.4s ease;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-content:hover .service-icon i {
    transform: scale(1.1) rotate(5deg);
}

.image-placeholder {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg,
        rgba(99, 102, 241, 0.1) 0%,
        rgba(6, 182, 212, 0.1) 50%,
        rgba(244, 63, 94, 0.1) 100%
    );
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(99, 102, 241, 0.08);
    transition: all 0.4s ease;
    overflow: hidden;
    border: 1px solid rgba(99, 102, 241, 0.1);
}

.image-placeholder i {
    transition: all 0.4s ease;
    background: linear-gradient(135deg,
        var(--primary-color) 0%,
        var(--secondary-color) 50%,
        var(--accent-color) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
}

.image-placeholder:hover i {
    transform: scale(1.1) rotate(5deg);
}

.approach-card {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 24px;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    padding: 3rem;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba(99, 102, 241, 0.1);
}

.approach-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg,
        rgba(99, 102, 241, 0.05) 0%,
        rgba(6, 182, 212, 0.05) 50%,
        rgba(244, 63, 94, 0.05) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
}

.approach-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 25px rgba(99, 102, 241, 0.15);
}

.approach-card:hover::before {
    opacity: 1;
}

.approach-icon {
    width: 88px;
    height: 88px;
    border-radius: 28px;
    background: linear-gradient(135deg,
        var(--accent-color) 0%,
        var(--primary-color) 50%,
        var(--secondary-color) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem;
    position: relative;
    border: 2px solid rgba(255, 255, 255, 0.1);
}

.approach-icon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    filter: blur(20px);
    opacity: 0.4;
    z-index: -1;
    transform: translateY(10px) scale(0.95);
}

.approach-icon i {
    color: #ffffff;
    font-size: 36px;
    transition: all 0.4s ease;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.approach-card:hover .approach-icon i {
    transform: scale(1.1) rotate(5deg);
}

.cta-card {
    border-radius: 28px;
    background: linear-gradient(135deg,
        var(--primary-color) 0%,
        var(--secondary-color) 50%,
        var(--accent-color) 100%
    );
    box-shadow: 0 15px 35px rgba(99, 102, 241, 0.25);
    position: relative;
    overflow: hidden;
    transition: all 0.4s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.cta-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    opacity: 0;
    transition: opacity 0.4s ease;
}

.cta-card:hover::before {
    opacity: 1;
}

.btn {
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    overflow: hidden;
}

.btn::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    background: linear-gradient(90deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
    );
    transition: left 0.4s ease;
}

.btn:hover::after {
    left: 100%;
}

.btn-outline-primary {
    border-width: 2px;
    border-color: var(--primary-color);
    color: var(--primary-color);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.btn-outline-primary:hover {
    transform: translateY(-3px);
    background: linear-gradient(135deg,
        var(--primary-color) 0%,
        var(--secondary-color) 100%
    );
    border-color: transparent;
    color: white;
    box-shadow: 0 8px 20px rgba(99, 102, 241, 0.25);
}

.text-muted {
    color: var(--text-secondary) !important;
}

h2, h3, h4 {
    color: var(--text-primary);
    font-weight: 700;
}

.badge.bg-primary {
    background: linear-gradient(135deg,
        var(--primary-color) 0%,
        var(--secondary-color) 50%,
        var(--accent-color) 100%
    ) !important;
    padding: 0.5em 1em;
    font-weight: 600;
    letter-spacing: 0.5px;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .service-card {
        margin-bottom: 2rem;
    }
    
    .image-placeholder {
        min-height: 200px;
        margin-top: 1rem;
    }

    .approach-card,
    .service-content {
        padding: 1.5rem;
    }
    
    .cta-card {
        padding: 2rem !important;
    }
} 