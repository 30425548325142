.it-operations-offer {
    padding: 50px 0;
}

.offer-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.offer-description {
    font-size: 1.2rem;
    color: #777;
}

.infrastructure-section,
.system-management-section,
.tools-section,
.cloud-operations-section {
    margin-top: 50px;
}

.tools-section ul {
    text-align: left;
}

.cta-btn {
    margin-top: 30px;
}
