.wordpress-container {
  direction: ltr;
  text-align: left;
  overflow-x: hidden;
}

.wordpress-hero {
  background: linear-gradient(135deg, #0073aa 0%, #00a0d2 100%);
  color: white;
  padding: 80px 0;
  margin-bottom: 60px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.main-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
}

.meta-description {
  font-size: 1.25rem;
  line-height: 1.6;
  max-width: 800px;
  margin-bottom: 2rem;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333;
  text-align: center;
}

/* Features Section */
.features-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-card {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card h3 {
  color: #0073aa;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

/* SEO Benefits Section */
.seo-benefits {
  padding: 60px 0;
}

.benefits-list ul {
  list-style: none;
  padding: 0;
}

.benefits-list li {
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}

.benefits-list li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #0073aa;
  font-weight: bold;
}

/* FAQ Section */
.faq-section {
  padding: 60px 0;
  background-color: #f8f9fa;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.faq-item {
  background: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.faq-item h3 {
  color: #0073aa;
  margin-bottom: 15px;
  font-size: 1.25rem;
}

/* CTA Section */
.wordpress-container .cta-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #00a0d2 0%, #0073aa 100%);
  color: white;
  text-align: center;
}

.wordpress-container .cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.wordpress-container .btn-primary, 
.wordpress-container .btn-secondary {
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  text-decoration: none;
  display: inline-block;
}

.wordpress-container .btn-primary {
  background-color: #fff;
  color: #0073aa;
}

.wordpress-container .btn-primary:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
}

.wordpress-container .btn-secondary {
  background-color: transparent;
  border: 2px solid white;
  color: white;
}

.wordpress-container .btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

/* Summary Section */
.wordpress-container .summary-section {
  padding: 60px 0;
  text-align: center;
}

.wordpress-container .final-cta {
  margin-top: 30px;
}

/* Pricing Section */
.wordpress-container .pricing-section {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.wordpress-container .pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.wordpress-container .pricing-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  position: relative;
}

.wordpress-container .pricing-card:hover {
  transform: translateY(-5px);
}

.wordpress-container .pricing-card.popular {
  border: 2px solid #0073aa;
}

.wordpress-container .popular-badge {
  position: absolute;
  top: 12px;
  left: 12px;
  background: #0073aa;
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
}

.wordpress-container .pricing-card-header {
  padding: 25px;
  background: #f8f9fa;
  text-align: center;
}

.wordpress-container .plan-name {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.wordpress-container .price-container {
  margin-top: 10px;
}

.wordpress-container .price {
  font-size: 2rem;
  font-weight: 700;
  color: #0073aa;
}

.wordpress-container .period {
  color: #666;
  font-size: 1rem;
}

.wordpress-container .pricing-card-body {
  padding: 25px;
}

.wordpress-container .specs-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.wordpress-container .specs-list li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #666;
}

.wordpress-container .specs-list li i {
  margin-right: 10px;
  color: #0073aa;
  width: 20px;
  text-align: center;
}

.wordpress-container .custom-plan .pricing-card-header {
  background: linear-gradient(135deg, #0073aa 0%, #00a0d2 100%);
  color: white;
}

.wordpress-container .custom-plan .plan-name {
  color: white;
}

.wordpress-container .custom-text {
  font-size: 1.2rem;
  font-weight: 600;
}

.wordpress-container .custom-description {
  text-align: center;
  margin-bottom: 20px;
}

.wordpress-container .custom-description i {
  font-size: 2rem;
  color: #0073aa;
  margin-bottom: 15px;
}

.wordpress-container .enterprise-features {
  margin-top: 20px;
}

.wordpress-container .enterprise-feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}

.wordpress-container .enterprise-feature i {
  color: #0073aa;
  margin-right: 15px;
  margin-top: 3px;
}

.wordpress-container .enterprise-feature-text h5 {
  margin: 0 0 5px 0;
  color: #333;
}

.wordpress-container .enterprise-feature-text p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.wordpress-container .contact-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.wordpress-container .contact-btn i {
  margin-right: 8px;
}

/* Included Features Section */
.wordpress-container .included-features {
  margin-top: 60px;
  background: none;
  padding: 0 20px;
  max-width: 100%;
  box-sizing: border-box;
}

.wordpress-container .features-header {
  text-align: center;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.wordpress-container .features-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 10px;
}

.wordpress-container .features-subtitle {
  color: #333;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-weight: 500;
  line-height: 1.5;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.wordpress-container .features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.wordpress-container .feature-box {
  display: flex;
  align-items: center;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.wordpress-container .feature-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background: #f8f9fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.wordpress-container .feature-icon i {
  color: #0073aa;
  font-size: 1.2rem;
}

.wordpress-container .feature-text {
  font-size: 1rem;
  color: #333;
  flex: 1;
}

.wordpress-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.wordpress-logo svg {
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s ease;
}

.wordpress-logo svg:hover {
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .wordpress-container .main-title {
    font-size: 2rem;
  }
  
  .wordpress-container .meta-description {
    font-size: 1.1rem;
  }
  
  .wordpress-container .features-grid, 
  .wordpress-container .faq-grid {
    grid-template-columns: 1fr;
  }
  
  .wordpress-container .cta-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .wordpress-container .btn-primary, 
  .wordpress-container .btn-secondary {
    width: 100%;
    max-width: 300px;
  }
}

/* Responsive Design for Pricing */
@media (max-width: 768px) {
  .wordpress-container .pricing-grid {
    grid-template-columns: 1fr;
  }
  
  .wordpress-container .features-container {
    grid-template-columns: 1fr;
  }
  
  .wordpress-container .price {
    font-size: 1.8rem;
  }
  
  .wordpress-container .enterprise-feature {
    flex-direction: column;
    text-align: center;
  }
  
  .wordpress-container .enterprise-feature i {
    margin: 0 0 10px 0;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wordpress-container .features-container {
    grid-template-columns: 1fr;
  }
  
  .wordpress-container .included-features {
    padding: 0 15px;
  }
  
  .wordpress-container .feature-box {
    margin: 0;
  }
} 