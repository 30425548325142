.academy-solutions-offer {
    padding: 50px 0;
  }
  
  .academy-solutions-offer h2 {
    color: #333;
    font-size: 2.5rem;
  }
  
  .academy-solutions-offer h3 {
    color: #555;
    font-size: 1.8rem;
  }
  
  .academy-solutions-offer p {
    color: #777;
    font-size: 1.2rem;
  }
  
  .academy-solutions-offer .btn {
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1.2rem;
  }
  
  .academy-solutions-offer .btn:hover {
    background-color: #0056b3;
  }
  