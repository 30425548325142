.woocommerce-container {
  direction: rtl;
  text-align: right;
  overflow-x: hidden;
}

.woocommerce-container[dir="rtl"] {
  direction: rtl;
  text-align: right;
}

.woocommerce-container[dir="ltr"] {
  direction: ltr;
  text-align: left;
}

.woocommerce-hero {
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  color: white;
  padding: 120px 0 80px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.woocommerce-logo img {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.main-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.gradient-text {
  display: block;
  font-size: 2rem;
  background: linear-gradient(45deg, #fff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0.5rem;
}

.meta-description {
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.hero-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
}

.section-subtitle {
  display: inline-block;
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(150, 88, 138, 0.2);
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333;
  position: relative;
  padding-bottom: 1rem;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  border-radius: 2px;
}

/* Features Section */
.features-section {
  padding: 100px 0;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.features-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom right, #fff 0%, #fff 50%, transparent 50%, transparent 100%);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature-card {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
}

.feature-card:hover::before {
  opacity: 1;
}

.feature-icon {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: white;
  font-size: 1.5rem;
  box-shadow: 0 4px 10px rgba(150, 88, 138, 0.2);
}

.feature-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.feature-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-card li {
  margin-bottom: 1rem;
  padding-right: 2rem;
  position: relative;
  color: #666;
  line-height: 1.5;
}

.feature-card li i {
  position: absolute;
  right: 0;
  top: 4px;
  color: #96588a;
}

/* Benefits Section */
.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.benefit-card {
  background: white;
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.benefit-card:hover {
  transform: translateY(-5px);
}

.benefit-card i {
  font-size: 2.5rem;
  color: #96588a;
  margin-bottom: 1rem;
}

.benefit-card h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.benefit-card p {
  color: #666;
  margin: 0;
  line-height: 1.5;
}

/* Support Section */
.support-section {
  padding: 100px 0;
  background: #fff;
}

.support-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.support-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.support-feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.support-feature i {
  font-size: 2rem;
  color: #96588a;
  margin-left: 1.5rem;
}

.support-feature h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.support-feature p {
  color: #666;
  margin: 0;
  line-height: 1.5;
}

/* Scalability Section */
.scalability-section {
  padding: 100px 0;
  background: #f8f9fa;
}

.scalability-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.scalability-card {
  background: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.scalability-card:hover {
  transform: translateY(-5px);
}

.scalability-card i {
  font-size: 2.5rem;
  color: #96588a;
  margin-bottom: 1.5rem;
}

.scalability-card h3 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.scalability-card p {
  color: #666;
  margin: 0;
  line-height: 1.5;
}

/* CTA Section */
.cta-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.cta-section p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

/* Summary Section */
.summary-section {
  padding: 100px 0;
  background: #fff;
  text-align: center;
}

.summary-content {
  max-width: 800px;
  margin: 0 auto;
}

.summary-icon {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #96588a 0%, #7c3977 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 2rem;
  color: white;
  font-size: 2rem;
  box-shadow: 0 10px 30px rgba(150, 88, 138, 0.2);
}

/* Buttons */
.btn-primary,
.btn-secondary {
  display: inline-flex;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.btn-primary {
  background: white;
  color: #96588a;
  border: none;
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.2);
}

.btn-secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 255, 255, 0.3);
}

.btn-secondary:hover {
  background: white;
  color: #96588a;
  transform: translateY(-2px);
}

.btn-primary i,
.btn-secondary i {
  margin-left: 10px;
  font-size: 1.1rem;
}

/* Responsive Design */
@media (max-width: 991px) {
  .main-title {
    font-size: 2.5rem;
  }

  .gradient-text {
    font-size: 1.75rem;
  }

  .support-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .support-image {
    text-align: center;
  }
}

@media (max-width: 768px) {
  .main-title {
    font-size: 2rem;
  }

  .gradient-text {
    font-size: 1.5rem;
  }

  .meta-description {
    font-size: 1.1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .hero-buttons,
  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }

  .btn-primary,
  .btn-secondary {
    width: 100%;
    max-width: 300px;
    justify-content: center;
  }

  .feature-card {
    padding: 30px;
  }
}

/* Animations */
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.woocommerce-logo {
  animation: float 3s ease-in-out infinite;
}

/* Scroll Animations */
[data-aos] {
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s ease;
}

[data-aos].aos-animate {
  opacity: 1;
  transform: translateY(0);
}

/* Pricing Modal Styles */
.pricing-modal {
  font-family: system-ui, -apple-system, sans-serif;
}

.pricing-modal .modal-content {
  border-radius: 20px;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.pricing-modal .modal-header {
  border-bottom: none;
  padding: 2rem;
}

.pricing-modal .modal-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
}

.pricing-modal .modal-body {
  padding: 0 2rem 2rem;
}

.deployment-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.info-box {
  flex: 1;
  min-width: 200px;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.info-box i {
  font-size: 1.5rem;
  color: #96588a;
}

.info-box.highlight {
  background: #96588a;
  color: white;
}

.info-box.highlight i {
  color: white;
}

.pricing-card {
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  padding: 2rem;
  height: 100%;
  position: relative;
  transition: transform 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #96588a;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.pricing-card-header {
  text-align: center;
  margin-bottom: 2rem;
}

.plan-name {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.english-name {
  font-size: 1rem;
  color: #666;
}

.price-container {
  margin-bottom: 1rem;
}

.price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #96588a;
}

.period {
  color: #666;
  font-size: 1rem;
}

.specs-list {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

.specs-list li {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  color: #666;
}

.specs-list i {
  color: #96588a;
  font-size: 1.2rem;
}

.terms-disclaimer {
  text-align: center;
  margin-bottom: 1.5rem;
}

.terms-disclaimer a {
  color: #96588a;
  text-decoration: none;
}

.select-plan-btn {
  width: 100%;
  padding: 1rem;
  font-weight: 600;
  border-radius: 10px;
}

.custom-plan {
  background: linear-gradient(135deg, #f8f9fa 0%, #fff 100%);
}

.custom-text {
  font-size: 1.2rem;
  color: #96588a;
  font-weight: 600;
}

.custom-description {
  text-align: center;
  margin-bottom: 2rem;
}

.custom-description i {
  font-size: 2.5rem;
  color: #96588a;
  margin-bottom: 1rem;
}

.enterprise-features {
  margin-bottom: 2rem;
}

.enterprise-feature {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.enterprise-feature i {
  color: #96588a;
  font-size: 1.2rem;
}

.enterprise-feature-text h5 {
  margin: 0 0 0.5rem;
  color: #333;
}

.enterprise-feature-text p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.features-section {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid #eee;
}

.features-header {
  text-align: center;
  margin-bottom: 2rem;
}

.features-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.features-subtitle {
  color: #666;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.feature-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 10px;
}

.feature-box i {
  color: #96588a;
  font-size: 1.2rem;
}

/* Responsive Design for Modal */
@media (max-width: 768px) {
  .deployment-info {
    flex-direction: column;
  }
  
  .info-box {
    width: 100%;
  }
  
  .pricing-card {
    margin-bottom: 2rem;
  }
}

/* RTL-specific styles */
[dir="rtl"] .feature-card li {
  padding-right: 2rem;
  padding-left: 0;
}

[dir="rtl"] .feature-card li i {
  right: 0;
  left: auto;
}

[dir="rtl"] .btn-primary i,
[dir="rtl"] .btn-secondary i {
  margin-left: 10px;
  margin-right: 0;
}

/* LTR-specific styles */
[dir="ltr"] .feature-card li {
  padding-left: 2rem;
  padding-right: 0;
}

[dir="ltr"] .feature-card li i {
  left: 0;
  right: auto;
}

[dir="ltr"] .btn-primary i,
[dir="ltr"] .btn-secondary i {
  margin-right: 10px;
  margin-left: 0;
}

/* RTL specific overrides */
[dir="rtl"] .woocommerce-container {
  direction: rtl !important;
  text-align: right !important;
}

[dir="rtl"] .feature-card,
[dir="rtl"] .pricing-card,
[dir="rtl"] .faq-item {
  text-align: right !important;
}

[dir="rtl"] .specs-list {
  padding-right: 0;
}

[dir="rtl"] .specs-list li {
  display: flex;
  flex-direction: row-reverse;
}

[dir="rtl"] .specs-list li i {
  margin-left: 10px;
  margin-right: 0;
}

[dir="rtl"] .popular-badge {
  right: 12px;
  left: auto;
}

[dir="rtl"] .enterprise-feature {
  flex-direction: row-reverse;
}

[dir="rtl"] .enterprise-feature i {
  margin-left: 15px;
  margin-right: 0;
}

[dir="rtl"] .enterprise-feature-text {
  text-align: right;
}

[dir="rtl"] .feature-box {
  flex-direction: row-reverse;
}

[dir="rtl"] .feature-icon {
  margin-left: 15px;
  margin-right: 0;
}

[dir="rtl"] .feature-text {
  text-align: right;
}

[dir="rtl"] .btn i {
  margin-left: 8px;
  margin-right: 0;
}

[dir="rtl"] .modal-header .btn-close {
  margin: -0.5rem auto -0.5rem -0.5rem;
}

[dir="rtl"] .modal-title {
  text-align: right;
}

[dir="rtl"] .modal-body {
  text-align: right;
}

[dir="rtl"] .cta-buttons {
  flex-direction: row-reverse;
}

[dir="rtl"] .hero-content {
  text-align: right;
}

[dir="rtl"] .meta-description {
  text-align: right;
}

[dir="rtl"] .section-header {
  text-align: right;
}

[dir="rtl"] .feature-card p {
  text-align: right;
}

[dir="rtl"] .pricing-card-body {
  text-align: right;
}

/* Mobile Responsiveness for RTL */
@media (max-width: 768px) {
  [dir="rtl"] .cta-buttons {
    flex-direction: column;
  }
  
  [dir="rtl"] .hero-content,
  [dir="rtl"] .meta-description,
  [dir="rtl"] .section-header,
  [dir="rtl"] .feature-card,
  [dir="rtl"] .pricing-card {
    text-align: right !important;
  }
} 