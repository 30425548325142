.marketplace-container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  background-color: #f8f9fc;
  padding-bottom: 4rem;
}

.background-icons {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(255,255,255,0.95), rgba(255,255,255,0.98));
}

.background-icon {
  position: absolute;
  color: var(--primary-color, #0078a8);
  transform-origin: center;
  will-change: transform;
  transition: all 0.5s ease;
  filter: drop-shadow(0 0 8px rgba(0, 120, 168, 0.2));
}

/* Float Up and Down Animation */
@keyframes floatUpDown {
  0%, 100% { transform: translateY(0) rotate(0deg) scale(1); }
  25% { transform: translateY(-25px) rotate(5deg) scale(1.1); }
  75% { transform: translateY(25px) rotate(-5deg) scale(0.9); }
}

/* Float Left and Right Animation */
@keyframes floatLeftRight {
  0%, 100% { transform: translateX(0) rotate(0deg) scale(1); }
  25% { transform: translateX(-25px) rotate(-5deg) scale(1.1); }
  75% { transform: translateX(25px) rotate(5deg) scale(0.9); }
}

/* Rotate Animation */
@keyframes rotate {
  from { transform: rotate(0deg) scale(1); }
  50% { transform: rotate(180deg) scale(1.2); }
  to { transform: rotate(360deg) scale(1); }
}

/* Pulse Animation */
@keyframes pulse {
  0%, 100% { 
    transform: scale(1); 
    opacity: var(--base-opacity, 0.15); 
    filter: drop-shadow(0 0 8px rgba(0, 120, 168, 0.2));
  }
  50% { 
    transform: scale(1.2); 
    opacity: calc(var(--base-opacity, 0.15) * 2); 
    filter: drop-shadow(0 0 15px rgba(0, 120, 168, 0.4));
  }
}

/* Bounce Animation */
@keyframes bounce {
  0%, 100% { transform: translateY(0) scale(1); }
  50% { transform: translateY(-30px) scale(1.1); }
}

/* Add hover effects */
.background-icon:hover {
  --base-opacity: 0.4;
  filter: drop-shadow(0 0 15px rgba(0, 120, 168, 0.6));
  transition: all 0.3s ease;
  transform: scale(1.3) !important;
  z-index: 5 !important;
}

/* Add media query for reduced animations on mobile */
@media (max-width: 768px) {
  .background-icons {
    display: none; /* Hide on very small screens */
  }
}

@media (prefers-reduced-motion: reduce) {
  .background-icon {
    animation: none !important;
    transform: none !important;
  }
}

/* Add depth layers */
.background-icon[style*="z-index: 0"] {
  --base-opacity: 0.12;
  filter: blur(0.5px) drop-shadow(0 0 8px rgba(0, 120, 168, 0.15));
  font-size: 24px !important;
}

.background-icon[style*="z-index: 1"] {
  --base-opacity: 0.15;
  filter: blur(0px) drop-shadow(0 0 10px rgba(0, 120, 168, 0.2));
  font-size: 32px !important;
}

.background-icon[style*="z-index: 2"] {
  --base-opacity: 0.18;
  filter: blur(0px) drop-shadow(0 0 12px rgba(0, 120, 168, 0.25));
  font-size: 40px !important;
}

/* Add glowing effect */
@keyframes glow {
  0%, 100% {
    filter: drop-shadow(0 0 8px rgba(0, 120, 168, 0.2));
  }
  50% {
    filter: drop-shadow(0 0 15px rgba(0, 120, 168, 0.4));
  }
}

.background-icon {
  animation: glow 3s infinite ease-in-out;
}

/* Make sure content is above background */
.marketplace-header,
.main-content,
.content-section,
.sidebar {
  position: relative;
  z-index: 1;
}

/* Header Section */
.marketplace-header {
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  padding: 4rem 0;
  color: white;
  margin-bottom: 3rem;
  position: relative;
  overflow: hidden;
}

.marketplace-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%232d3748' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
}

.header-content {
  position: relative;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header-content h1 {
  font-size: 3rem;
  font-weight: 800;
  margin: 0;
  letter-spacing: -1px;
  background: linear-gradient(to right, #fff, #e2e8f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-subtitle {
  font-size: 1.35rem;
  opacity: 0.9;
  margin: 0;
  max-width: 600px;
  line-height: 1.6;
}

/* Language Switch Styles */
.language-switch {
  display: flex;
  gap: 4px;
  background: rgba(255, 255, 255, 0.15);
  padding: 6px;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.language-switch .btn {
  min-width: 56px;
  height: 48px;
  font-weight: 700;
  font-size: 1.1rem;
  border-radius: 12px;
  padding: 0 16px;
  transition: all 0.3s ease;
  border: none;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.language-switch .btn-primary {
  background: linear-gradient(135deg, #3b82f6 0%, #1d4ed8 100%);
  color: white;
  box-shadow: 0 6px 16px rgba(37, 99, 235, 0.4);
}

.language-switch .btn-outline-primary {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.95);
  border: none;
  backdrop-filter: blur(4px);
}

.language-switch .btn-outline-primary:hover {
  background: rgba(255, 255, 255, 0.25);
  color: white;
  border: none;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
}

.language-switch .btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.2), rgba(255,255,255,0));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.language-switch .btn:hover::before {
  opacity: 1;
}

.language-switch .btn:active {
  transform: translateY(2px);
}

.language-switch .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(37, 99, 235, 0.5);
}

/* Responsive Design for Language Switch */
@media (max-width: 768px) {
  .language-switch {
    padding: 4px;
    border-radius: 14px;
  }

  .language-switch .btn {
    min-width: 48px;
    height: 42px;
    font-size: 1rem;
    padding: 0 14px;
  }
}

@media (max-width: 480px) {
  .language-switch .btn {
    min-width: 44px;
    height: 40px;
    font-size: 0.95rem;
    padding: 0 12px;
  }
}

/* Main Content */
.main-content {
  padding: 0 2rem 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Sidebar */
.sidebar {
  position: sticky;
  top: 2rem;
  background: white;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.sidebar-section {
  margin-bottom: 2rem;
}

.sidebar-section h5 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #0f172a;
  margin-bottom: 1.5rem;
  letter-spacing: -0.3px;
  position: relative;
  padding-bottom: 0.75rem;
}

.sidebar-section h5::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 3px;
  background: #A2FADD;
  border-radius: 2px;
}

[dir="rtl"] .sidebar-section h5::after {
  left: auto;
  right: 0;
}

.category-list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.category-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #475569;
  text-decoration: none;
  padding: 0.875rem 1.25rem;
  font-size: 1rem;
  border-radius: 12px;
  transition: all 0.2s ease;
  background: #f8fafc;
  border: 1px solid transparent;
  font-weight: 500;
  position: relative;
  overflow: hidden;
}

.category-link:hover {
  color: #0f172a;
  background: #f1f5f9;
  border-color: #e2e8f0;
  transform: translateX(4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.category-link.active {
  color: #0f172a;
  background: #A2FADD;
  font-weight: 600;
  transform: translateX(4px);
  border-color: rgba(0, 0, 0, 0.05);
}

.category-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #A2FADD;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.category-link:hover::before,
.category-link.active::before {
  opacity: 1;
}

.category-count {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 600;
  background: white;
  padding: 0.35rem 0.875rem;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-width: 32px;
  text-align: center;
  transition: all 0.2s ease;
}

.category-link:hover .category-count {
  background: #A2FADD;
  color: #0f172a;
}

.category-link.active .category-count {
  background: white;
  color: #0f172a;
}

/* RTL Support for Categories */
[dir="rtl"] .category-link {
  flex-direction: row-reverse;
}

[dir="rtl"] .category-link:hover {
  transform: translateX(-4px);
}

[dir="rtl"] .category-link.active {
  transform: translateX(-4px);
}

[dir="rtl"] .category-link::before {
  left: auto;
  right: 0;
}

/* Responsive Design for Categories */
@media (max-width: 768px) {
  .sidebar {
    position: static;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }

  .category-link {
    padding: 0.75rem 1rem;
    font-size: 0.95rem;
  }

  .category-count {
    padding: 0.25rem 0.75rem;
    font-size: 0.8rem;
  }
}

/* Content Section */
.content-section {
  padding-left: 2rem;
}

.search-section {
  margin-bottom: 2.5rem;
  background: white;
  padding: 1.75rem;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.search-section .input-group {
  max-width: 100%;
}

.search-section .form-control {
  height: 3.5rem;
  font-size: 1.1rem;
  border-radius: 12px 0 0 12px;
  border: 2px solid #e2e8f0;
  padding: 0 1.75rem;
}

.search-section .btn {
  padding: 0 2rem;
  font-weight: 600;
  font-size: 1.1rem;
}

/* Products List */
.products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.product-card {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  transition: all 0.3s ease;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, #0f172a, #1e293b);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.product-card:hover {
  border-color: #cbd5e1;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  transform: translateY(-4px);
}

.product-card:hover::before {
  opacity: 1;
}

.product-card .card-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.product-card .card-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #0f172a;
  margin: 0;
  letter-spacing: -0.3px;
}

.provider-badge {
  font-size: 0.8rem;
  color: #64748b;
  padding: 0.35rem 0.75rem;
  background: #f8fafc;
  border-radius: 20px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.product-card .card-text {
  color: #475569;
  font-size: 1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
  flex-grow: 1;
}

.product-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 1.25rem;
  border-top: 1px solid #f1f5f9;
  flex-wrap: wrap;
  gap: 1rem;
}

.product-footer-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pricing-tag {
  font-size: 0.9rem;
  color: #0f766e;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

[dir="rtl"] .price-wrapper {
  align-items: flex-end;
}

.starting-from {
  font-size: 0.75rem;
  color: #64748b;
  font-weight: 500;
  margin-bottom: -0.25rem;
}

.pricing-tag .amount {
  font-size: 1.5rem;
  font-weight: 800;
  color: #0f766e;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}

.pricing-tag .period {
  font-size: 0.85rem;
  color: #64748b;
  font-weight: 500;
}

.deploy-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.25rem;
}

.product-footer .btn {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 12px;
  background: #0284c7;
  border-color: #0284c7;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  text-transform: uppercase;
}

.product-footer .btn:hover {
  background: #0369a1;
  border-color: #0369a1;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.managed-label {
  font-size: 0.75rem;
  color: #64748b;
  font-weight: 500;
}

.category-tag {
  font-size: 0.85rem;
  color: #64748b;
  padding: 0.35rem 0.75rem;
  background: #f8fafc;
  border-radius: 20px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 4rem 2rem;
  background: white;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.empty-state h3 {
  font-size: 1.5rem;
  color: #0f172a;
  margin-bottom: 1rem;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.empty-state p {
  color: #64748b;
  max-width: 400px;
  margin: 0 auto;
  line-height: 1.6;
}

/* RTL Support */
[dir="rtl"] .sidebar {
  border-right: none;
  border-left: none;
}

[dir="rtl"] .content-section {
  padding-left: 0;
  padding-right: 2rem;
}

[dir="rtl"] .category-link {
  text-align: end;
}

[dir="rtl"] .category-link:hover,
[dir="rtl"] .category-link.active {
  transform: translateX(-4px);
}

[dir="rtl"] .search-section .form-control {
  border-radius: 0 8px 8px 0;
}

[dir="rtl"] .search-section .btn {
  border-radius: 8px 0 0 8px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .products-list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .marketplace-header {
    padding: 3rem 0;
  }

  .header-content h1 {
    font-size: 2.25rem;
    text-align: center;
  }

  .header-subtitle {
    font-size: 1.1rem;
    text-align: center;
    margin: 0 auto;
  }

  .main-content {
    padding: 0 1rem 2rem;
  }

  .sidebar {
    position: static;
    margin-bottom: 2rem;
    padding: 1.5rem;
  }

  .search-section {
    padding: 1.25rem;
    margin-bottom: 2rem;
  }

  .search-section .form-control {
    height: 3.25rem;
    font-size: 1rem;
  }

  .products-list {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .product-card .card-body {
    padding: 1.5rem;
  }

  .product-card .card-title {
    font-size: 1.25rem;
  }

  .product-footer {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
  }

  .product-footer-left {
    flex-direction: column;
    align-items: flex-start;
  }

  .deploy-button-wrapper {
    align-items: stretch;
  }

  .product-footer .btn {
    width: 100%;
  }

  /* Modal Mobile Improvements */
  .deploy-modal .modal-header {
    padding: 1.5rem;
  }

  .deploy-modal .modal-title {
    font-size: 1.5rem;
  }

  .deploy-modal .modal-body {
    padding: 1.5rem;
  }

  .plan-card {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .features-section {
    margin-top: 2rem;
  }

  .feature-item {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .header-content h1 {
    font-size: 2rem;
  }

  .product-card .card-body {
    padding: 1.25rem;
  }

  .pricing-tag .amount {
    font-size: 1.25rem;
  }

  .category-tag, .provider-badge {
    font-size: 0.8rem;
  }
}

/* Touch Device Optimizations */
@media (hover: none) {
  .product-card:hover {
    transform: none;
  }

  .plan-card:hover {
    transform: none;
  }

  .product-footer .btn:active,
  .plan-card:active {
    transform: translateY(2px);
  }
}

/* Bootstrap Overrides */
.btn-primary {
  background-color: #0284c7;
  border-color: #0284c7;
}

.btn-primary:hover {
  background-color: #0369a1;
  border-color: #0369a1;
}

.btn-outline-primary {
  border-color: #0284c7;
  color: #0284c7;
}

.btn-outline-primary:hover {
  background-color: #0284c7;
  border-color: #0284c7;
}

.form-control:focus {
  border-color: #0f172a;
  box-shadow: 0 0 0 3px rgba(15, 23, 42, 0.1);
}

/* Modern Pricing Modal Styles */
.deploy-modal .modal-content {
  border-radius: 24px;
  border: none;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.deploy-modal .modal-header {
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  padding: 2.5rem 3rem;
  border: none;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.deploy-modal .modal-header .close {
  position: absolute;
  padding: 1rem;
  margin: 0;
  top: 1.5rem;
  opacity: 0.8;
  color: white;
  font-size: 1.5rem;
  transition: all 0.2s ease;
  z-index: 10;
}

[dir="rtl"] .deploy-modal .modal-header .close {
  left: 1.5rem;
  right: auto;
}

[dir="ltr"] .deploy-modal .modal-header .close {
  right: 1.5rem;
  left: auto;
}

.deploy-modal .modal-header .close:hover {
  opacity: 1;
  transform: scale(1.1);
}

.deploy-modal .modal-title {
  font-size: 2.25rem;
  font-weight: 800;
  color: white;
  margin: 0;
  letter-spacing: -1px;
  position: relative;
  flex-grow: 1;
  text-align: center;
}

[dir="rtl"] .deploy-modal .modal-title {
  margin-left: 3rem;
  margin-right: 0;
}

[dir="ltr"] .deploy-modal .modal-title {
  margin-right: 3rem;
  margin-left: 0;
}

/* Responsive adjustments for modal header */
@media (max-width: 768px) {
  .deploy-modal .modal-header {
    padding: 2rem;
  }

  .deploy-modal .modal-header .close {
    top: 1rem;
  }

  [dir="rtl"] .deploy-modal .modal-header .close {
    left: 1rem;
  }

  [dir="ltr"] .deploy-modal .modal-header .close {
    right: 1rem;
  }

  .deploy-modal .modal-title {
    font-size: 1.75rem;
  }
}

.deploy-modal .modal-body {
  padding: 0;
  background: #f8fafc;
}

.modal-scroll-container {
  max-height: 80vh;
  overflow-y: auto;
  padding: 3rem;
}

.features-section + .plans-container {
  margin-top: 3rem;
}

.plans-container {
  max-width: 1200px;
  margin: 0 auto;
}

.plan-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  height: 100%;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.plan-card:hover {
  border-color: #0284c7;
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.plan-header {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}

.plan-name {
  font-size: 1.5rem;
  font-weight: 700;
  color: #0f172a;
  margin-bottom: 0.5rem;
  letter-spacing: -0.5px;
}

.plan-price {
  font-size: 3.5rem;
  font-weight: 800;
  color: #0284c7;
  line-height: 1;
  margin: 1rem 0;
  letter-spacing: -2px;
}

.plan-price .currency {
  font-size: 1.75rem;
  font-weight: 600;
  vertical-align: super;
  margin-right: 0.25rem;
  letter-spacing: -1px;
}

.plan-price .period {
  font-size: 1.1rem;
  color: #64748b;
  font-weight: 500;
  letter-spacing: normal;
}

.plan-specs {
  margin: 2rem 0;
}

.spec-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #f1f5f9;
  color: #475569;
  font-size: 1rem;
}

.spec-item:last-child {
  border-bottom: none;
}

.spec-item i {
  color: #0284c7;
  font-size: 1.25rem;
}

.plan-card .btn {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 12px;
  text-transform: none;
  letter-spacing: -0.3px;
}

/* Custom Plan Card */
.custom-plan {
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
  border: 2px dashed #cbd5e1;
}

.custom-plan:hover {
  border-style: dashed;
  background: linear-gradient(135deg, #f1f5f9 0%, #e2e8f0 100%);
}

.custom-plan .plan-header {
  padding-bottom: 0;
  border-bottom: none;
}

.custom-plan .plan-price {
  font-size: 2rem;
  color: #64748b;
}

.custom-plan .plan-specs {
  text-align: center;
  padding: 2rem 1rem;
}

.custom-plan p {
  color: #475569;
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 0;
}

/* Features Section New Design */
.features-section {
  background: #ffffff;
  border-radius: 16px;
  padding: 40px;
  margin-top: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.features-header {
  text-align: center;
  margin-bottom: 40px;
}

.features-title {
  font-size: 2rem;
  font-weight: 700;
  color: #1a237e;
  margin-bottom: 16px;
}

.features-subtitle {
  color: #64748b;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  padding: 20px 0;
}

.feature-box {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
  background: #f8fafc;
  border-radius: 12px;
  transition: all 0.3s ease;
  border: 1px solid #e2e8f0;
  position: relative;
  overflow: hidden;
}

.feature-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-box:hover::before {
  opacity: 1;
}

.feature-box:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-color: #e2e8f0;
}

.feature-icon {
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.feature-icon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-box:hover .feature-icon::before {
  opacity: 1;
}

.feature-icon i {
  color: white;
  font-size: 1.25rem;
  transition: transform 0.3s ease;
}

.feature-box:hover .feature-icon i {
  transform: scale(1.1);
}

/* Custom colors for different feature icons */
.feature-box:nth-child(1) .feature-icon { background: linear-gradient(135deg, #3b82f6 0%, #1d4ed8 100%); }
.feature-box:nth-child(2) .feature-icon { background: linear-gradient(135deg, #8b5cf6 0%, #6d28d9 100%); }
.feature-box:nth-child(3) .feature-icon { background: linear-gradient(135deg, #ec4899 0%, #be185d 100%); }
.feature-box:nth-child(4) .feature-icon { background: linear-gradient(135deg, #10b981 0%, #047857 100%); }
.feature-box:nth-child(5) .feature-icon { background: linear-gradient(135deg, #f59e0b 0%, #b45309 100%); }
.feature-box:nth-child(6) .feature-icon { background: linear-gradient(135deg, #6366f1 0%, #4338ca 100%); }
.feature-box:nth-child(7) .feature-icon { background: linear-gradient(135deg, #14b8a6 0%, #0f766e 100%); }
.feature-box:nth-child(8) .feature-icon { background: linear-gradient(135deg, #f43f5e 0%, #be123c 100%); }
.feature-box:nth-child(9) .feature-icon { background: linear-gradient(135deg, #8b5cf6 0%, #6d28d9 100%); }
.feature-box:nth-child(10) .feature-icon { background: linear-gradient(135deg, #06b6d4 0%, #0e7490 100%); }

.feature-text {
  font-size: 1rem;
  color: #334155;
  font-weight: 500;
  line-height: 1.5;
}

/* RTL Support for Features */
[dir="rtl"] .feature-box {
  flex-direction: row-reverse;
  text-align: right;
}

/* Responsive Design for Features */
@media (max-width: 768px) {
  .features-section {
    padding: 24px;
  }

  .features-title {
    font-size: 1.75rem;
  }

  .features-container {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .feature-box {
    padding: 16px;
  }

  .feature-icon {
    width: 40px;
    height: 40px;
  }

  .feature-icon i {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .features-section {
    padding: 20px;
  }

  .features-title {
    font-size: 1.5rem;
  }

  .features-subtitle {
    font-size: 1rem;
  }
}

/* Pricing Modal Styles */
.pricing-plans-container {
  padding: 20px;
}

.pricing-row {
  margin-bottom: 40px;
}

.pricing-card {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 25px;
  height: 100%;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.pricing-card.popular {
  border: 2px solid #007bff;
  transform: scale(1.02);
}

.popular-badge {
  position: absolute;
  top: -12px;
  right: 20px;
  background: #007bff;
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
}

.pricing-card-header {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
}

.plan-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 5px;
}

.price {
  font-size: 2.5rem;
  font-weight: 700;
  color: #007bff;
}

.period {
  color: #666;
  font-size: 1rem;
}

.specs-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

.specs-list li {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: #555;
}

.specs-list li i {
  margin-right: 10px;
  color: #007bff;
  width: 20px;
}

.select-plan-btn {
  width: 100%;
  padding: 12px;
  font-weight: 600;
  margin-top: auto;
}

/* Custom Plan Styles */
.custom-plan {
  background: linear-gradient(145deg, #f8f9fa 0%, #e9ecef 100%);
}

.custom-plan .price-container.custom {
  margin: 15px 0;
}

.custom-text {
  font-size: 1.2rem;
  color: #6c757d;
  font-weight: 500;
}

.custom-description {
  text-align: center;
  margin: 20px 0;
  color: #666;
}

.custom-description i {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 15px;
}

.contact-btn {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
}

.contact-btn i {
  margin-right: 8px;
}

/* Features Section Styles */
.features-section {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.features-title {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-weight: 600;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
}

.feature-item {
  margin-bottom: 15px;
}

.feature-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.feature-content i {
  color: #28a745;
  font-size: 1.1rem;
}

.feature-content span {
  color: #555;
}

/* RTL Support */
[dir="rtl"] .specs-list li i {
  margin-right: 0;
  margin-left: 10px;
}

[dir="rtl"] .contact-btn i {
  margin-right: 0;
  margin-left: 8px;
}

[dir="rtl"] .popular-badge {
  right: auto;
  left: 20px;
}

/* Modal Responsive Styles */
@media (max-width: 768px) {
  .pricing-card {
    margin-bottom: 20px;
  }
  
  .pricing-card.popular {
    transform: none;
  }
  
  .features-grid {
    margin-top: 20px;
  }
}

/* Special styling for Full Management feature */
.feature-box:first-child {
  background: linear-gradient(to right, #f0f9ff, #ffffff);
  border: 2px solid #3b82f6;
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(59, 130, 246, 0.15);
}

.feature-box:first-child::before {
  opacity: 1;
  width: 6px;
  background: linear-gradient(to bottom, #3b82f6, #1d4ed8);
}

.feature-box:first-child .feature-icon {
  width: 56px;
  height: 56px;
  background: linear-gradient(135deg, #3b82f6 0%, #1d4ed8 100%);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.feature-box:first-child .feature-icon i {
  font-size: 1.5rem;
  color: #ffffff;
}

.feature-box:first-child .feature-text {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1e40af;
}

.feature-box:first-child::after {
  content: '';
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  background: linear-gradient(45deg, rgba(59, 130, 246, 0.1), transparent);
  border-radius: 12px;
  pointer-events: none;
}

/* Hover state for the first feature box */
.feature-box:first-child:hover {
  transform: scale(1.05) translateY(-4px);
  background: linear-gradient(to right, #f0f9ff, #f8fafc);
  border-color: #2563eb;
  box-shadow: 0 12px 28px rgba(59, 130, 246, 0.2);
}

.feature-box:first-child:hover .feature-icon {
  transform: scale(1.1);
}

.feature-box:first-child:hover .feature-icon i {
  transform: scale(1.1) rotate(10deg);
}

/* RTL Support for first feature box */
[dir="rtl"] .feature-box:first-child::before {
  right: 0;
  left: auto;
}

/* Responsive adjustments for first feature box */
@media (max-width: 768px) {
  .feature-box:first-child {
    transform: scale(1.02);
  }
  
  .feature-box:first-child:hover {
    transform: scale(1.02) translateY(-4px);
  }
  
  .feature-box:first-child .feature-icon {
    width: 48px;
    height: 48px;
  }
  
  .feature-box:first-child .feature-icon i {
    font-size: 1.25rem;
  }
}

/* Enterprise Features Styles */
.enterprise-features {
  margin: 25px 0;
  padding: 20px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  border: 1px solid #e2e8f0;
}

.enterprise-feature {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-bottom: 12px;
  background: white;
  border: 1px solid #f0f4f8;
}

.enterprise-feature:last-child {
  margin-bottom: 0;
}

.enterprise-feature:hover {
  transform: translateX(8px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: #3b82f6;
}

.enterprise-feature i {
  font-size: 1.5rem;
  color: #3b82f6;
  background: #f0f7ff;
  padding: 12px;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.enterprise-feature-text {
  flex: 1;
}

.enterprise-feature-text h5 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #1e40af;
  margin: 0 0 4px 0;
}

.enterprise-feature-text p {
  font-size: 0.95rem;
  color: #64748b;
  margin: 0;
  line-height: 1.5;
}

/* RTL Support for Enterprise Features */
[dir="rtl"] .enterprise-feature:hover {
  transform: translateX(-8px);
}

[dir="rtl"] .enterprise-feature {
  text-align: right;
}

/* Responsive Design for Enterprise Features */
@media (max-width: 768px) {
  .enterprise-features {
    padding: 15px;
  }

  .enterprise-feature {
    padding: 12px;
  }

  .enterprise-feature i {
    font-size: 1.25rem;
    padding: 10px;
    width: 40px;
    height: 40px;
  }

  .enterprise-feature-text h5 {
    font-size: 1rem;
  }

  .enterprise-feature-text p {
    font-size: 0.9rem;
  }
}

.terms-disclaimer {
  text-align: center;
  margin: 10px 0;
  padding: 0 15px;
}

.terms-disclaimer small {
  color: #666;
  font-size: 0.85rem;
}

.terms-disclaimer a {
  color: #0056b3;
  text-decoration: none;
}

.terms-disclaimer a:hover {
  text-decoration: underline;
}

.beta-badge {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 2px 8px;
  margin-left: 12px;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

[dir="rtl"] .beta-badge {
  margin-left: 0;
  margin-right: 12px;
}

@media (max-width: 768px) {
  .beta-badge {
    font-size: 0.8rem;
    padding: 2px 6px;
    margin-left: 8px;
  }

  [dir="rtl"] .beta-badge {
    margin-left: 0;
    margin-right: 8px;
  }
}

/* Upgrades Section Styles */
.upgrades-section {
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid #e2e8f0;
}

.upgrades-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1.5rem 0;
}

.upgrade-box {
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.upgrade-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, #3b82f6, #2563eb);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.upgrade-box:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-color: #3b82f6;
}

.upgrade-box:hover::before {
  opacity: 1;
}

.upgrade-icon {
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.upgrade-icon i {
  font-size: 1.5rem;
  color: #fff;
}

.upgrade-content {
  flex: 1;
}

.upgrade-content h5 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1a237e;
  margin-bottom: 0.75rem;
}

.upgrade-content p {
  color: #64748b;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
}

.upgrade-price {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.upgrade-price .price {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3b82f6;
}

.upgrade-price .period {
  color: #64748b;
  font-size: 0.9rem;
}

.upgrade-note {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #64748b;
}

.upgrade-note i {
  color: #3b82f6;
}

/* RTL Support for Upgrades */
[dir="rtl"] .upgrade-box {
  flex-direction: row-reverse;
}

[dir="rtl"] .upgrade-box::before {
  right: 0;
  left: auto;
}

[dir="rtl"] .upgrade-price {
  flex-direction: row-reverse;
}

/* Responsive Styles for Upgrades */
@media (max-width: 768px) {
  .upgrades-container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .upgrade-box {
    padding: 1.5rem;
  }

  .upgrade-icon {
    width: 40px;
    height: 40px;
  }

  .upgrade-icon i {
    font-size: 1.25rem;
  }

  .upgrade-content h5 {
    font-size: 1.1rem;
  }

  .upgrade-price .price {
    font-size: 1.25rem;
  }
}

/* Deployment Info Styles */
.deployment-info {
  background: #f8fafc;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid #e2e8f0;
}

.info-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  flex: 1;
  min-width: 280px;
  max-width: 400px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.info-box.highlight {
  background: linear-gradient(to right, #f0f9ff, #ffffff);
  border: 2px solid #3b82f6;
  transform: scale(1.05);
  box-shadow: 0 8px 24px rgba(59, 130, 246, 0.15);
}

.info-box.highlight::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, #3b82f6, #1d4ed8);
}

.info-box.highlight i {
  background: linear-gradient(135deg, #3b82f6 0%, #1d4ed8 100%);
  color: white;
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.info-box.highlight p {
  font-weight: 600;
  color: #1e40af;
}

[dir="rtl"] .info-box.highlight::before {
  left: auto;
  right: 0;
}

.info-box i {
  font-size: 1.5rem;
  color: #3b82f6;
  background: #f0f7ff;
  padding: 0.75rem;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-box p {
  margin: 0;
  font-size: 1rem;
  color: #475569;
  line-height: 1.5;
}

.info-box a {
  color: #3b82f6;
  text-decoration: none;
  font-weight: 500;
}

.info-box a:hover {
  text-decoration: underline;
}

/* RTL Support for Deployment Info */
[dir="rtl"] .info-box {
  flex-direction: row-reverse;
  text-align: right;
}

/* Responsive Design for Deployment Info */
@media (max-width: 768px) {
  .deployment-info {
    padding: 1rem;
    gap: 1rem;
  }

  .info-box {
    padding: 0.75rem 1rem;
    min-width: 100%;
  }

  .info-box i {
    font-size: 1.25rem;
    padding: 0.5rem;
    width: 40px;
    height: 40px;
  }
} 