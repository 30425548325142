/* Modern UI Variables */
:root {
  --feature-color-1: #6366f1;
  --feature-color-2: #8b5cf6;
  --feature-color-3: #ec4899;
  --feature-color-4: #14b8a6;
  --feature-color-5: #f59e0b;
  --feature-color-6: #06b6d4;
  --card-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  --card-hover-shadow: 0 20px 40px rgba(108, 92, 231, 0.15);
  --transition-bezier: cubic-bezier(0.165, 0.84, 0.44, 1);
  --primary-color: #6154FF;
  --secondary-color: #000DFF;
  --accent-color: #4338CA;
  --accent-color-rgb: 67, 56, 202;
  --text-color: #4a5568;
}

/* Modern Section Styling */
.feature-section {
  padding: 4rem 0;
  background: linear-gradient(180deg, #f8fafc 0%, #ffffff 100%);
  overflow: hidden;
}

/* RTL Support */
[dir="rtl"] .feature-section {
  direction: rtl;
  text-align: right;
}

.modern-header {
  margin-bottom: 3rem;
}

.modern-tag {
  display: inline-block;
  background: rgba(74, 144, 226, 0.1);
  border-radius: 30px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 10px rgba(74, 144, 226, 0.1);
}

.modern-title {
  font-weight: 800;
  letter-spacing: -0.5px;
}

.gradient-text {
  background: linear-gradient(135deg, #1a365d 0%, #2563eb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
}

.gradient-text-secondary {
  background: linear-gradient(135deg, #4338ca 0%, #6366f1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
}

.highlight-box {
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.1) 0%, rgba(147, 51, 234, 0.1) 100%);
  padding: 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  font-size: 1.5rem;
}

.modern-description {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #4a5568;
  margin-bottom: 30px;
}

.modern-highlight {
  color: #2d3748;
  font-weight: 600;
}

.modern-strong {
  color: #4a5568;
  font-weight: 600;
}

.modern-call-to-action {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(135deg, #4f46e5 0%, #9333ea 100%);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 16px;
}

/* Feature Grid Styling */
.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 2rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.modern-feature-card {
  position: relative;
  background: white;
  border-radius: 24px;
  padding: 2.5rem;
  transition: all 0.3s var(--transition-bezier);
  overflow: hidden;
  box-shadow: var(--card-shadow);
  height: 350px; /* Fixed height */
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
}

.modern-feature-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-hover-shadow);
}

.modern-feature-card .feature-icon {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  transition: all 0.3s var(--transition-bezier);
  flex-shrink: 0;
}

.modern-feature-card .feature-icon i {
  font-size: 28px;
  color: white;
}

.modern-feature-card .feature-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.modern-feature-card h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #1a202c;
  flex-shrink: 0;
  min-height: 2em; /* Fixed height for title */
}

.modern-feature-card p {
  font-size: 1rem;
  line-height: 1.7;
  color: #4a5568;
  margin-bottom: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  flex: 1;
  min-height: 6.8em; /* Fixed height for description - 4 lines */
}

.modern-feature-card .feature-arrow {
  height: 24px;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.3s var(--transition-bezier);
  flex-shrink: 0;
  margin-top: auto;
}

.modern-feature-card:hover .feature-arrow {
  opacity: 1;
  transform: translateX(0);
}

.modern-feature-card .feature-arrow i {
  font-size: 1.2rem;
  color: var(--feature-color-1);
}

/* RTL Specific Styles */
[dir="rtl"] .modern-feature-card {
  text-align: right;
}

[dir="rtl"] .modern-feature-card .feature-icon {
  margin-left: 1rem;
  margin-right: 0;
}

[dir="rtl"] .modern-feature-card .feature-arrow {
  transform: scaleX(-1);
  margin-right: auto;
  margin-left: 0;
}

[dir="rtl"] .section-header {
  text-align: right;
}

[dir="rtl"] .section-tag {
  margin-right: 0;
  margin-left: 1rem;
}

[dir="rtl"] .highlight-list li {
  padding-right: 2rem;
  padding-left: 0;
}

[dir="rtl"] .highlight-list li i {
  right: 0;
  left: auto;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .features-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1rem;
  }

  .modern-feature-card {
    height: auto;
    min-height: 300px;
    padding: 2rem;
  }

  .modern-feature-card .feature-icon {
    width: 56px;
    height: 56px;
    margin: 0 auto 1.5rem auto;
  }

  .modern-feature-card h3,
  .modern-feature-card p {
    text-align: center;
  }

  .modern-feature-card p {
    -webkit-line-clamp: 3;
    min-height: 5.1em; /* Fixed height for description - 3 lines on mobile */
  }
}

/* Platform CTA Section */
.platform-cta {
  background: linear-gradient(135deg, rgba(99, 102, 241, 0.05) 0%, rgba(139, 92, 246, 0.05) 100%);
  border-radius: 24px;
  padding: 60px 40px;
  text-align: center;
  margin-top: 80px;
}

.platform-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: white;
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
}

.platform-badge i {
  color: #6366f1;
  font-size: 20px;
}

.platform-badge span {
  color: #4a5568;
  font-weight: 600;
  font-size: 0.9rem;
}

.platform-title {
  font-size: 2.5rem;
  font-weight: 800;
  color: #1a202c;
  margin-bottom: 20px;
  line-height: 1.3;
}

.platform-description {
  font-size: 1.1rem;
  color: #4a5568;
  max-width: 700px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.platform-features {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.platform-feature {
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  padding: 12px 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.platform-feature:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(99, 102, 241, 0.1);
}

.platform-feature i {
  color: #6366f1;
  font-size: 20px;
}

.platform-feature span {
  color: #1a202c;
  font-weight: 600;
  font-size: 0.95rem;
}

.platform-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.platform-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 12px;
  font-weight: 600;
  transition: all 0.3s ease;
  text-decoration: none;
  width: auto;
  white-space: nowrap;
  gap: 8px;
}

.platform-btn.primary {
  background: #6366f1;
  color: white !important;
  border: none;
  transition: transform 0.2s ease;
}

.platform-btn.green {
  background: #059669;
  color: white !important;
  border: none;
  transition: transform 0.2s ease;
}

.platform-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.15);
  text-decoration: none;
  color: white;
}

.platform-btn i {
  font-size: 1.2em;
  display: inline-flex;
  align-items: center;
  transition: none;
}

.text-center.mt-4 {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .text-center.mt-4 {
    flex-direction: column;
    width: 100%;
    padding: 0 1rem;
  }

  .platform-btn {
    width: 100%;
    justify-content: center;
    padding: 1rem;
    font-size: 1rem;
    margin: 0.5rem 0;
    text-align: center;
  }

  .platform-btn i {
    font-size: 1.1em;
  }

  .button-text {
    flex: 1;
    text-align: center;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .modern-title {
    font-size: 2.2rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .modern-feature-card {
    padding: 2rem;
  }
  
  .highlight-title {
    font-size: 1.8rem;
  }
}

/* RTL Support */
.rtl-section {
  direction: rtl;
  text-align: right;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.section-header.modern-header {
  text-align: right;
  margin-bottom: 3rem;
}

.modern-title {
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
}

.gradient-text-secondary {
  display: inline-block;
  margin: 0.5rem 0;
}

.highlight-box {
  display: inline-block;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  line-height: 1.4;
}

.modern-description {
  text-align: right;
  line-height: 1.6;
  margin: 1.5rem 0;
}

.feature-text {
  display: block;
  margin: 1rem 0;
  line-height: 1.6;
}

.feature-box, .feature-box-secondary {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin: 0.75rem 0;
  padding: 1rem 1.5rem;
  width: auto;
  max-width: 100%;
  text-align: right;
}

.feature-box i, .feature-box-secondary i {
  margin-left: 8px;
  margin-right: 0;
}

.feature-text-large {
  display: block;
  margin: 1.5rem 0;
  line-height: 1.5;
}

.modern-call-to-action {
  margin: 1.5rem 0;
  padding: 1rem 2rem;
  display: inline-flex;
  align-items: center;
  gap: 12px;
}

.modern-call-to-action i {
  margin-left: 8px;
  margin-right: 0;
}

/* Fix icon directions for RTL */
.la-arrow-right {
  transform: scaleX(-1);
}

/* Responsive improvements */
@media (max-width: 768px) {
  .modern-title {
    font-size: 2.2rem;
    line-height: 1.3;
  }

  .feature-box, .feature-box-secondary {
    flex-direction: row;
    justify-content: flex-start;
    text-align: right;
    padding: 0.75rem 1rem;
  }

  .feature-text, .feature-text-large {
    font-size: 1.1rem;
    margin: 1rem 0;
  }

  .modern-description {
    font-size: 1rem;
    line-height: 1.5;
  }
}

/* Fix spacing between elements */
.section-tag {
  margin-bottom: 1rem;
}

.highlight.modern-highlight {
  display: block;
  margin-bottom: 1.5rem;
}

br {
  display: none;
}

p.modern-description > * {
  margin: 1rem 0;
}

/* Improve text readability */
.text-emphasis {
  display: inline-block;
  margin: 0 0.25rem;
}

.text-gradient {
  display: inline-block;
}

/* Custom Feature Colors */
:root {
  --feature-color-1: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  --feature-color-2: linear-gradient(135deg, #3b82f6 0%, #2dd4bf 100%);
  --feature-color-3: linear-gradient(135deg, #f59e0b 0%, #f97316 100%);
  --feature-color-4: linear-gradient(135deg, #10b981 0%, #059669 100%);
  --feature-color-5: linear-gradient(135deg, #ef4444 0%, #dc2626 100%);
  --feature-color-6: linear-gradient(135deg, #8b5cf6 0%, #6366f1 100%);
}

/* Modern Marketing Styles */
.section-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.1) 0%, rgba(147, 51, 234, 0.1) 100%);
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.section-tag i {
  color: #6366f1;
}

.gradient-text {
  background: linear-gradient(135deg, #4f46e5 0%, #9333ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.gradient-text-secondary {
  background: linear-gradient(135deg, #3b82f6 0%, #06b6d4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 2.5rem;
}

.highlight-box {
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.1) 0%, rgba(147, 51, 234, 0.1) 100%);
  padding: 8px 16px;
  border-radius: 8px;
  margin-right: 12px;
  font-size: 1.5rem;
}

.feature-text {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #4b5563;
}

.text-emphasis {
  color: #4f46e5;
  font-weight: 600;
}

.feature-box, .feature-box-secondary {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  border-radius: 12px;
  margin: 8px 0;
  transition: all 0.3s ease;
}

.feature-box {
  background: linear-gradient(135deg, rgba(79, 70, 229, 0.1) 0%, rgba(147, 51, 234, 0.1) 100%);
  border: 1px solid rgba(79, 70, 229, 0.2);
}

.feature-box-secondary {
  background: linear-gradient(135deg, rgba(59, 130, 246, 0.1) 0%, rgba(6, 182, 212, 0.1) 100%);
  border: 1px solid rgba(59, 130, 246, 0.2);
}

.feature-text-large {
  font-size: 1.3rem;
  line-height: 1.6;
  display: block;
  margin: 16px 0;
}

.text-gradient {
  background: linear-gradient(135deg, #4f46e5 0%, #9333ea 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.modern-call-to-action {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: linear-gradient(135deg, #4f46e5 0%, #9333ea 100%);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 16px;
}

.modern-call-to-action:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(79, 70, 229, 0.2);
}

.modern-call-to-action i {
  font-size: 1.2em;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .gradient-text-secondary {
    font-size: 2rem;
  }
  
  .highlight-box {
    font-size: 1.2rem;
    display: block;
    margin: 12px 0;
  }
  
  .feature-box, .feature-box-secondary {
    display: block;
    text-align: center;
  }
}

.platform-onboarding {
  margin: 4rem 0;
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.05);
}

.platform-onboarding h3 {
  color: var(--primary-color);
  font-size: 2rem;
  margin-bottom: 2rem;
}

.onboarding-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.step {
  position: relative;
  text-align: center;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  transition: transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.step:hover {
  transform: translateY(-5px);
}

.step-number {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.step-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.step h4 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.step p {
  color: var(--text-color);
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .onboarding-steps {
    grid-template-columns: 1fr;
  }
  
  .step {
    margin-bottom: 2rem;
  }
}

/* CI/CD Pipeline Visualization */
.cicd-visualization {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 40px;
  background: white;
  border-radius: 15px;
  box-shadow: var(--card-shadow);
  margin-bottom: 30px;
  min-height: 150px;
  width: 100%;
}

.pipeline-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: linear-gradient(135deg, #6366f1 0%, #8b5cf6 100%);
  border-radius: 12px;
  color: white;
  min-width: 70px;
  height: 70px;
  transition: all 0.3s var(--transition-bezier);
}

.pipeline-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(99, 102, 241, 0.2);
}

.pipeline-step i {
  font-size: 24px;
}

.pipeline-step span {
  font-size: 14px;
  font-weight: 600;
}

.pipeline-arrow {
  color: #6366f1;
  font-size: 24px;
}

.tech-badges {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.tech-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 12px;
  box-shadow: var(--card-shadow);
  transition: all 0.3s var(--transition-bezier);
}

.tech-badge i {
  font-size: 28px;
  color: #6366f1;
}

.tech-badge:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-hover-shadow);
}

.feature-highlights {
  padding: 30px;
}

.highlight-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.highlight-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #4a5568;
}

.highlight-list li i {
  color: #6366f1;
  font-size: 20px;
}

@media (max-width: 768px) {
  .cicd-visualization {
    flex-direction: column;
    padding: 20px;
  }

  .pipeline-arrow {
    transform: rotate(90deg);
  }
}

.cicd-visualization-modern {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  position: relative;
  margin: 2rem 0;
  gap: 1.5rem;
}

.pipeline-hexagon {
  position: relative;
  width: 100px;
  height: 115px;
  background: linear-gradient(135deg, #6154FF, #000DFF);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);
}

.pipeline-hexagon:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0,0,0,0.15);
}

.hexagon-content {
  text-align: center;
  color: white;
}

.hexagon-content i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  display: block;
}

.hexagon-content span {
  font-size: 0.9rem;
  font-weight: 500;
}

.connection-line {
  position: absolute;
  right: -1.5rem;
  top: 50%;
  width: 1.5rem;
  height: 2px;
  background: linear-gradient(90deg, #6154FF, #000DFF);
  z-index: 1;
  opacity: 0.8;
}

.pipeline-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: linear-gradient(90deg, var(--accent-color), var(--primary-color));
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(var(--accent-color-rgb), 0.3);
}

.tech-badges-modern {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
}

.tech-badge-modern {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 12px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  cursor: pointer;
}

.tech-badge-modern i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tech-badge-modern span {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .cicd-visualization-modern {
    flex-direction: column;
    gap: 2rem;
  }

  .connection-line {
    right: 50%;
    top: calc(100% + 0.75rem);
    width: 2px;
    height: 1.5rem;
    transform: translateX(50%);
    background: linear-gradient(180deg, #6154FF, #000DFF);
  }

  .pipeline-hexagon {
    width: 90px;
    height: 103px;
  }
}

/* Modern Feature Section Styles */
.modern-feature-section {
  padding: 80px 0;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}

/* Hero Section Enhancements */
.hero-section {
  padding: 100px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-badge {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background: rgba(66, 133, 244, 0.1);
  border: 1px solid rgba(66, 133, 244, 0.2);
  border-radius: 50px;
  color: #4285F4;
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.hero-badge span {
  background: linear-gradient(135deg, #6154FF 0%, #000DFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.hero-title {
  font-size: 3.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.hero-description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #5f6368;
  margin-bottom: 2.5rem;
}

.highlight-text {
  color: #202124;
  font-weight: 600;
}

.emphasis-text {
  color: #4285F4;
  font-weight: 500;
}

.bold-text {
  font-weight: 700;
  color: #202124;
}

.hero-buttons {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.primary-button,
.secondary-button,
.marketplace-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.primary-button {
  background: linear-gradient(135deg, #4285F4 0%, #34A853 100%);
  color: white;
}

.secondary-button {
  background: linear-gradient(135deg, #FBBC04 0%, #EA4335 100%);
  color: white;
}

.marketplace-button {
  background: linear-gradient(135deg, #00C9FF 0%, #92FE9D 100%);
  border: none;
  padding: 12px 25px;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 8px 15px rgba(0, 201, 255, 0.2);
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.marketplace-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 201, 255, 0.3);
}

.marketplace-button::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(270deg, #00C9FF, #92FE9D, #00C9FF);
  background-size: 400% 400%;
  border-radius: 50px;
  z-index: -1;
  animation: borderGlow 8s ease infinite;
}

.marketplace-button .button-content {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 1;
}

.marketplace-button .button-content span {
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.1rem;
}

.marketplace-button .button-content i {
  font-size: 1.2rem;
  color: white;
  transition: transform 0.3s ease;
}

.marketplace-button:hover .button-content i {
  transform: scale(1.2);
}

@keyframes borderGlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.button-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.hero-stats {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.stat-number {
  font-size: 1.8rem;
  font-weight: 700;
  color: #2d3748;
  background: linear-gradient(135deg, #6154FF 0%, #000DFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  font-size: 0.9rem;
  color: #4a5568;
  font-weight: 500;
}

.hero-image-wrapper {
  position: relative;
  padding: 0.5rem;
}

.hero-image-container {
  position: relative;
  padding: 20px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.floating-elements {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.float-element {
  position: absolute;
  width: 40px;
  height: 40px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.float-element i {
  font-size: 1.5rem;
  background: linear-gradient(135deg, #6154FF 0%, #000DFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.float-element.code {
  top: 20%;
  left: 10%;
}

.float-element.cloud {
  top: 60%;
  right: 15%;
}

.float-element.rocket {
  top: 30%;
  right: 10%;
}

.hero-img {
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-description {
    font-size: 1.1rem;
  }
  
  .hero-buttons {
    flex-direction: column;
  }
  
  .hero-stats {
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .float-element {
    display: none;
  }
}

/* Platform Announcement */
.platform-announcement {
  background: linear-gradient(135deg, rgba(66, 133, 244, 0.1) 0%, rgba(52, 168, 83, 0.1) 100%);
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.announcement-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.announcement-icon {
  color: #4285F4;
  font-size: 1.5rem;
}

.announcement-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-badge {
  background: linear-gradient(135deg, #4285F4 0%, #34A853 100%);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.announcement-message {
  font-size: 1rem;
  font-weight: 500;
}

.announcement-arrow {
  background: rgba(255, 255, 255, 0.2);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Image Sizes */
.hero-img {
  max-width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: contain;
}

.service-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

/* Platform Onboarding Section */
.platform-onboarding-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
}

.platform-onboarding {
  background: white;
  border-radius: 20px;
  padding: 3rem 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.platform-onboarding h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #202124;
}

.onboarding-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.step {
  position: relative;
  background: white;
  border-radius: 15px;
  padding: 2rem 1.5rem;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.step:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.step-number {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  background: linear-gradient(135deg, #4285F4 0%, #34A853 100%);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.step-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.step h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #202124;
}

.step p {
  color: #6c757d;
  font-size: 0.9rem;
  line-height: 1.5;
}

.platform-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.platform-btn {
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.platform-btn.primary {
  background: linear-gradient(135deg, #4285F4 0%, #34A853 100%);
  color: white;
}

.platform-btn.green {
  background: linear-gradient(135deg, #34A853 0%, #4285F4 100%);
  color: white;
}

.platform-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .announcement-content {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }

  .announcement-text {
    flex-direction: column;
  }

  .platform-actions {
    flex-direction: column;
  }

  .platform-btn {
    width: 100%;
    justify-content: center;
  }

  .hero-img {
    max-height: 300px;
  }

  .service-image {
    height: 200px;
  }
}

/* Modern Services Grid */
.modern-services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  padding: 3rem 0;
}

/* Highlighted Cards Container */
.highlighted-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
  margin-bottom: 2.5rem;
}

/* Highlighted Card Styles */
.highlighted-card {
  grid-column: span 1 !important;
  background: linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%);
  min-height: 520px !important;
  padding: 3.5rem !important;
  position: relative;
  z-index: 2;
  transform: scale(1.02);
  border-radius: 40px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  box-shadow: 
    0 25px 50px rgba(0, 0, 0, 0.15),
    0 5px 15px rgba(0, 0, 0, 0.1),
    inset 0 0 60px rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.highlighted-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at top right,
    rgba(255, 255, 255, 0.2) 0%,
    transparent 60%
  );
  z-index: 1;
}

.highlighted-card .service-card-content {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.highlighted-card .service-icon {
  width: 130px;
  height: 130px;
  border-radius: 38px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  margin-bottom: 2rem;
  transform: rotate(-8deg);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.2),
    inset 0 2px 10px rgba(255, 255, 255, 0.2);
}

.highlighted-card .service-icon i {
  font-size: 4.2rem;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.highlighted-card:hover .service-icon {
  transform: rotate(8deg) scale(1.1);
  background: rgba(255, 255, 255, 0.2);
}

.highlighted-card h3 {
  font-size: 2.8rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  letter-spacing: -0.03em;
  line-height: 1.2;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.85));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.highlighted-card p {
  font-size: 1.3rem;
  line-height: 1.7;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.2rem;
  margin: 2rem 0;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.15rem;
  color: rgba(255, 255, 255, 0.9);
  padding: 0.8rem 1.2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateX(8px);
  background: rgba(255, 255, 255, 0.15);
}

.feature-item i {
  color: #A5B4FC;
  font-size: 1.6rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.saas-actions {
  display: flex;
  gap: 1.5rem;
  margin-top: 3rem;
}

.service-link {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1.2rem 2.5rem;
  border-radius: 20px;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
}

.service-link.primary {
  background: white;
  color: #4F46E5;
  box-shadow: 
    0 10px 25px rgba(0, 0, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
}

.service-link.secondary {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}

.service-link.primary:hover {
  transform: translateY(-5px);
  background: #f8fafc;
  color: #4338CA;
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.25),
    0 3px 8px rgba(0, 0, 0, 0.15);
}

.service-link.secondary:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.4);
  color: white;
}

.highlight-badge {
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  background: rgba(255, 255, 255, 0.98);
  color: #4338CA;
  padding: 0.8rem 1.8rem;
  border-radius: 30px;
  font-weight: 800;
  font-size: 1.1rem;
  box-shadow: 
    0 10px 25px rgba(0, 0, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 3;
  letter-spacing: 0.02em;
  transform: rotate(-3deg);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.highlighted-card:hover .highlight-badge {
  transform: rotate(3deg) scale(1.05);
  box-shadow: 
    0 15px 30px rgba(0, 0, 0, 0.25),
    0 3px 8px rgba(0, 0, 0, 0.15);
}

.highlighted-card:nth-child(1) {
  background: linear-gradient(135deg, #0EA5E9 0%, #2563EB 100%);
  box-shadow: 
    0 25px 50px rgba(14, 165, 233, 0.15),
    0 15px 30px rgba(37, 99, 235, 0.1),
    inset 0 5px 20px rgba(255, 255, 255, 0.1);
}

.highlighted-card:nth-child(2) {
  background: linear-gradient(135deg, #8B5CF6 0%, #6366F1 100%);
  box-shadow: 
    0 25px 50px rgba(139, 92, 246, 0.15),
    0 15px 30px rgba(99, 102, 241, 0.1),
    inset 0 5px 20px rgba(255, 255, 255, 0.1);
}

.highlighted-card:nth-child(1) .feature-item {
  background: linear-gradient(135deg, rgba(14, 165, 233, 0.2) 0%, rgba(37, 99, 235, 0.2) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.highlighted-card:nth-child(2) .feature-item {
  background: linear-gradient(135deg, rgba(139, 92, 246, 0.2) 0%, rgba(99, 102, 241, 0.2) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.highlighted-card:nth-child(1) .feature-item i {
  color: #BAE6FD;
}

.highlighted-card:nth-child(2) .feature-item i {
  color: #DDD6FE;
}

.highlighted-card:nth-child(1) .service-link.primary {
  background: white;
  color: #0EA5E9;
}

.highlighted-card:nth-child(2) .service-link.primary {
  background: white;
  color: #8B5CF6;
}

.highlighted-card:nth-child(1) .highlight-badge {
  color: #0EA5E9;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 
    0 10px 25px rgba(14, 165, 233, 0.2),
    0 5px 10px rgba(37, 99, 235, 0.1);
}

.highlighted-card:nth-child(2) .highlight-badge {
  color: #8B5CF6;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 
    0 10px 25px rgba(139, 92, 246, 0.2),
    0 5px 10px rgba(99, 102, 241, 0.1);
}

@media (max-width: 1200px) {
  .highlighted-cards-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .highlighted-card {
    padding: 2.5rem !important;
    min-height: auto !important;
  }

  .highlighted-card .service-icon {
    width: 100px;
    height: 100px;
    border-radius: 30px;
  }

  .highlighted-card .service-icon i {
    font-size: 3.5rem;
  }

  .highlighted-card h3 {
    font-size: 2.2rem;
  }

  .highlighted-card p {
    font-size: 1.15rem;
  }

  .feature-list {
    grid-template-columns: 1fr;
  }

  .saas-actions {
    flex-direction: column;
    gap: 1rem;
  }

  .service-link {
    width: 100%;
    justify-content: center;
  }

  .highlight-badge {
    top: 1.5rem;
    left: 1.5rem;
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
}

.modern-service-card {
  position: relative;
  border-radius: 30px;
  padding: 2.5rem;
  min-height: 340px;
  overflow: hidden;
  color: white;
  cursor: pointer;
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.05),
    inset 0 0 80px rgba(255, 255, 255, 0.05);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.modern-service-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at top right,
    rgba(255, 255, 255, 0.15) 0%,
    transparent 70%
  );
  z-index: 1;
}

.modern-service-card .service-card-content {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modern-service-card .service-icon {
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  transform: rotate(-5deg);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.15),
    inset 0 2px 8px rgba(255, 255, 255, 0.1);
}

.modern-service-card .service-icon i {
  font-size: 2.8rem;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.modern-service-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 
    0 30px 60px rgba(0, 0, 0, 0.15),
    0 8px 20px rgba(0, 0, 0, 0.1),
    inset 0 0 80px rgba(255, 255, 255, 0.08);
}

.modern-service-card:hover .service-icon {
  transform: rotate(5deg) scale(1.1);
  background: rgba(255, 255, 255, 0.2);
}

.modern-service-card h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.02em;
}

.modern-service-card p {
  font-size: 1.15rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 1.5rem;
  flex-grow: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.modern-service-card .service-link {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem 1.8rem;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  color: white;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: auto;
}

.modern-service-card .service-link:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: translateX(8px);
  border-color: rgba(255, 255, 255, 0.3);
}

.modern-service-card .service-link i {
  font-size: 1.2rem;
  transition: transform 0.4s ease;
}

.modern-service-card .service-link:hover i {
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .modern-services-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 1.5rem;
  }

  .modern-service-card {
    min-height: auto;
    padding: 2rem;
  }

  .modern-service-card .service-icon {
    width: 70px;
    height: 70px;
    border-radius: 20px;
  }

  .modern-service-card .service-icon i {
    font-size: 2.4rem;
  }

  .modern-service-card h3 {
    font-size: 1.6rem;
  }

  .modern-service-card p {
    font-size: 1.1rem;
  }

  .modern-service-card .service-link {
    width: 100%;
    justify-content: center;
    padding: 0.8rem 1.5rem;
  }
}

.cta-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #4285F4 0%, #34A853 100%);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.cta-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.9);
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 12px 30px;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.cta-button.primary {
  background: white;
  color: #4285F4;
  border: 2px solid white;
}

.cta-button.secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

/* Marketplace Promo Card Styles */
.marketplace-promo-card {
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  border-radius: 24px;
  padding: 2.5rem;
  margin-top: 3rem;
  color: white;
  box-shadow: 0 10px 30px rgba(37, 99, 235, 0.2);
  overflow: hidden;
  position: relative;
}

.marketplace-promo-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.marketplace-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
}

.marketplace-promo-card h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.marketplace-promo-card p {
  font-size: 1.1rem;
  opacity: 0.9;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.marketplace-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.15);
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.marketplace-link:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
  color: white;
}

.marketplace-link i {
  font-size: 1.2rem;
}

/* Add a subtle pattern overlay */
.marketplace-promo-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.344 0L13.858 8.485 15.272 9.9l7.9-7.9h-.828zm5.656 0L19.515 8.485 17.343 10.657 28 0h-2.83zM32.657 0L20.343 12.313 17.343 15.313 32.657 0zM32 0L18.343 13.657 32 0zm18.686 0L33.313 17.37 32 18.687 53.313 0h-2.627zM0 5.373l.828-.83L2.243 5.96 0 8.2V5.374zm0 5.656l.828-.829L5.657 5.373 0 11.03V11zm0 5.657l.828-.828L8.485 8.485 0 16.686V16.69zm0 5.657l.828-.828L11.313 11.31 0 22.344v-2.83zm0 5.657l.828-.828L14.142 14.14 0 28v-2.83zm0 5.657l.828-.828L16.97 16.97 0 33.657V33.66zm0 5.657l.828-.828L19.8 19.8 0 39.313v-2.627zm0 5.657l.828-.828L22.626 22.627 0 45v-2.686zm0 5.657l.828-.828L25.456 25.456 0 50.657V50.66zm0 5.657l.828-.828L28.284 28.284 0 56.313v-2.627zm0 5.657l.828-.828L31.112 31.112 0 62v-2.686zm0-48L3.657 7.37 0 11.03V9zM0 0l3.657 3.657L0 5.657V0zm0 16.686l3.657-3.657L0 16.686zm0 5.657l3.657-3.657L0 22.343zm0 5.657l3.657-3.657L0 28zm0 5.657l3.657-3.657L0 33.657zm0 5.657l3.657-3.657L0 39.314zm0 5.657l3.657-3.657L0 45zm0 5.657l3.657-3.657L0 50.657zm0 5.657l3.657-3.657L0 56.314zm0 5.657l.828-.828L31.112 31.112 0 62zM5.373 0L8.2 2.828 5.373 0zm5.657 0L16.686 2.828 11.03 0zm5.657 0L22.344 2.828 16.687 0zm5.657 0L28 2.828 22.344 0zm5.657 0L33.657 2.828 28 0zm5.657 0L39.313 2.828 33.657 0zm5.657 0L45 2.828 39.314 0zm5.657 0L50.657 2.828 45 0zm5.657 0L56.313 2.828 50.657 0zm5.657 0L62 2.828 56.314 0z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .marketplace-promo-card {
    padding: 2rem;
  }

  .marketplace-promo-card h3 {
    font-size: 1.75rem;
  }

  .marketplace-promo-card p {
    font-size: 1rem;
  }
}

.marketing-keywords-section {
  padding: 6rem 2rem;
  background: #f8fafc;
  position: relative;
  overflow: hidden;
}

.keywords-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.keyword-card {
  transition: all 0.4s ease;
}

.keyword-card:hover {
  transform: translateY(-8px);
}

.card-content {
  height: 100%;
  transition: all 0.3s ease;
}

.card-content:hover {
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .marketing-keywords-section {
    padding: 4rem 1rem;
  }
  
  .keywords-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
  