.software-development-offer {
    padding: 50px 0;
}

.offer-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.offer-description {
    font-size: 1.2rem;
    color: #777;
}

.custom-dev-section,
.devops-integration-section,
.technology-stack-section,
.saas-products-section {
    margin-top: 50px;
}

.technology-stack-section ul {
    text-align: left;
}

.cta-btn {
    margin-top: 30px;
}

.option-label.selected {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
    transform: scale(1.05);
  }
  
  .option-label:hover {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
    transform: scale(1.05);
  }
  
  /* .option-checkbox {
    display: none;
  } */
  
  .option-checkbox:checked + .option-label {
    background-color: var(--primary-color);
    color: #000000;
    border-color: var(--primary-color);
    transform: scale(1.05);
  }
  
  .button-group {
    margin-top: 20px;
  }
  
  .button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: var(--primary-dark);
  }
  
  .button:disabled {
    background-color: var(--disabled-background);
    cursor: not-allowed;
  }
  .contact-form {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .contact-form h2 {
    margin-bottom: 10px;
  }
  
  .contact-form input[type="email"],
  .contact-form input[type="tel"],
  .contact-form button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  /* Optional: Adjust styles for smaller screens */
  @media (max-width: 768px) {
    .contact-form {
      padding: 15px;
    }
  }
  * {
    box-sizing: border-box;
  } 
    :root {
    --primary-color: #007bff;
    --primary-dark: #0056b3;
    --light-background: #f8f9fa;
    --text-color: #333;
    --border-color: #007bff;
    --disabled-background: #ccc;
    --shadow-color: rgba(0, 0, 0, 0.1);
  }

  * {
    box-sizing: border-box;
  } 

 
  

  .header, .question {
    color: var(--text-color);
  }

  

  .question {
    margin-bottom: 20px;
    font-size: 30px;
  }

  .options-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    justify-content: center;
  }

  .option-label {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 16px;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    background-color: transparent;
    color: var(--primary-color);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, color 0.3s, border-color 0.3s;
  }

  .option-label:hover {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
    transform: scale(1.05);
  }

    .option-checkbox {
      /* display: none; */
     
      
        
    }

    .option-checkbox:checked + .option-label {
      background-color: var(--primary-color);
      color: #fff;
      border-color: var(--primary-color);
      transform: scale(1.05); /* Adjust the scale transformation for the selected state */
    }

    .button-group {
      margin-top: 20px;
    }

    .button {
      padding: 12px 24px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: var(--primary-color);
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s;
    }

    .button:hover {
      background-color: var(--primary-dark);
    }

    .button:disabled {
      background-color: var(--disabled-background);
      cursor: not-allowed;
    }

.option-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 25px;
  border: 3px solid var(--border-color);
  border-radius: 6px;
  background-color: transparent;
  color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, color 0.3s, border-color 0.3s;
}

.option-label.selected {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  transform: scale(1.05);
}

.option-label:hover {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
  transform: scale(1.05);
}

/* .option-checkbox {
  display: none;
} */

.option-checkbox:checked + .option-label {
  background-color: var(--primary-color);
  color: #000000;
  border-color: var(--primary-color);
  transform: scale(1.05);
}

.button-group {
  margin-top: 20px;
}

.button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: var(--primary-dark);
}

.button:disabled {
  background-color: var(--disabled-background);
  cursor: not-allowed;
}
.contact-form {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.contact-form h2 {
  margin-bottom: 10px;
}

.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Optional: Adjust styles for smaller screens */
@media (max-width: 768px) {
  .contact-form { 
    padding: 15px;
  }
}