/* Modern About Us Section Styling */
:root {
  --primary-gradient-start: #2563eb;
  --primary-gradient-end: #3b82f6;
  --accent-color: #f59e0b;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --bg-light: #f8fafc;
  --card-bg: rgba(255, 255, 255, 0.98);
  --hover-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  --card-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
  --glass-bg: rgba(255, 255, 255, 0.7);
}

.about-section {
  padding: 140px 0;
  background: linear-gradient(135deg, var(--bg-light) 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at top right, rgba(37, 99, 235, 0.08) 0%, transparent 70%),
    radial-gradient(circle at bottom left, rgba(245, 158, 11, 0.08) 0%, transparent 70%);
  pointer-events: none;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;
}

/* Image Styling */
.image-container {
  position: relative;
  width: 100%;
  height: 600px;
  perspective: 1500px;
  margin-left: 40px;
}

.main-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: var(--hover-shadow);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  transform-style: preserve-3d;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(37, 99, 235, 0.4),
    rgba(59, 130, 246, 0.2)
  );
  z-index: 1;
  mix-blend-mode: overlay;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  transform: scale(1.1);
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.main-image-wrapper:hover {
  transform: rotateY(-5deg) rotateX(5deg);
}

.main-image-wrapper:hover .main-image {
  transform: scale(1);
}

/* Stats Card */
.stats-card {
  position: absolute;
  bottom: 40px;
  right: -20px;
  background: var(--glass-bg);
  backdrop-filter: blur(15px);
  padding: 25px;
  border-radius: 20px;
  box-shadow: var(--hover-shadow);
  z-index: 2;
  min-width: 240px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.stats-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.stats-icon {
  width: 50px;
  height: 50px;
  border-radius: 15px;
  background: linear-gradient(135deg, var(--primary-gradient-start), var(--primary-gradient-end));
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(37, 99, 235, 0.2);
}

.stats-icon i {
  font-size: 1.8rem;
  color: white;
}

.stats-info h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(135deg, var(--primary-gradient-start), var(--primary-gradient-end));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stats-info p {
  margin: 5px 0 0;
  font-size: 1rem;
  color: var(--text-secondary);
}

/* Experience Badge */
.experience-badge {
  position: absolute;
  top: 40px;
  right: -20px;
  background: var(--glass-bg);
  backdrop-filter: blur(15px);
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: var(--hover-shadow);
  z-index: 2;
  text-align: center;
  min-width: 180px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.experience-badge .years {
  display: block;
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1;
  background: linear-gradient(135deg, var(--primary-gradient-start), var(--primary-gradient-end));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.experience-badge .text {
  display: block;
  font-size: 1rem;
  color: var(--text-secondary);
  margin-top: 8px;
  font-weight: 500;
}

/* Content Styling */
.content-wrapper {
  padding-right: 40px;
}

.subtitle {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary-gradient-start);
  background: rgba(37, 99, 235, 0.1);
  padding: 14px 35px;
  border-radius: 100px;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
}

.title {
  font-size: 3.5rem;
  line-height: 1.1;
  margin-bottom: 30px;
  font-weight: 800;
  letter-spacing: -1px;
  color: var(--text-primary);
}

.title span {
  display: block;
}

.gradient-text {
  background: linear-gradient(135deg, var(--primary-gradient-start), var(--primary-gradient-end));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 10px;
}

.description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: var(--text-secondary);
  margin-bottom: 50px;
  font-weight: 400;
}

/* Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  margin: 40px 0;
}

.feature-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 25px;
  background: var(--glass-bg);
  border-radius: 20px;
  box-shadow: var(--card-shadow);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.feature-item:hover {
  transform: translateY(-8px);
  box-shadow: var(--hover-shadow);
}

.feature-icon {
  width: 60px;
  height: 60px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s ease;
  flex-shrink: 0;
}

.feature-icon i {
  font-size: 1.8rem;
  color: white;
}

.feature-content {
  flex: 1;
}

.feature-content h4 {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 8px;
}

.feature-content p {
  font-size: 1rem;
  color: var(--text-secondary);
  line-height: 1.6;
  margin: 0;
}

/* Action Button */
.action-wrapper {
  margin-top: 50px;
}

.btn-custom {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  padding: 18px 40px;
  background: linear-gradient(135deg, var(--primary-gradient-start), var(--primary-gradient-end));
  color: white;
  text-decoration: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 15px 30px rgba(37, 99, 235, 0.3);
}

.btn-custom:hover {
  transform: translateY(-3px);
  box-shadow: var(--hover-shadow);
  color: white;
}

.btn-custom i {
  font-size: 1.3rem;
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-custom:hover i {
  transform: translateX(8px);
}

/* Responsive Design */
@media (max-width: 991px) {
  .about-section {
    padding: 100px 0;
  }

  .title {
    font-size: 3rem;
  }

  .content-wrapper {
    padding-right: 0;
    margin-bottom: 60px;
  }

  .image-container {
    height: 500px;
    margin-left: 0;
  }

  .stats-card,
  .experience-badge {
    right: 0;
  }
}

@media (max-width: 768px) {
  .about-section {
    padding: 80px 0;
  }

  .title {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1.1rem;
  }

  .image-container {
    height: 400px;
  }

  .stats-card {
    padding: 20px;
    min-width: 200px;
  }

  .experience-badge {
    padding: 15px 25px;
  }

  .feature-item {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .about-section {
    padding: 60px 0;
  }

  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
    padding: 10px 25px;
  }

  .image-container {
    height: 350px;
  }

  .stats-card,
  .experience-badge {
    position: relative;
    right: 0;
    margin-top: 20px;
    width: 100%;
  }

  .feature-item {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .feature-content h4 {
    font-size: 1.1rem;
  }

  .btn-custom {
    width: 100%;
    justify-content: center;
    padding: 15px 30px;
  }
} 
