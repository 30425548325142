
.button-container {
    text-align: center;
    margin: 20px 0;
}

.stylish-button {
    background: linear-gradient(45deg, rgb(38, 147, 219), #2575fc);
    border: none;
    color: white;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 0.3s ease;
}

.stylish-button:hover {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.stylish-button:active {
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
