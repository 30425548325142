/* Contact section styles */
.contact-heading {
    font-size: 2em;
    margin-bottom: 1.5rem;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.contact-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 2em;
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;
}

.flag-icon {
    width: 30px;
    height: auto;
    vertical-align: middle;
}

.phone-link {
    color: inherit;
    text-decoration: none;
    transition: color 0.3s ease;
}

.phone-link:hover {
    color: #00c991;
}

.email-link {
    color: #00c991;
    text-decoration: none;
    transition: color 0.3s ease;
}

.email-link:hover {
    color: #008c65;
}

/* Responsive styles */
@media (max-width: 768px) {
    .contact-heading {
        font-size: 1.5em;
    }

    .contact-item {
        font-size: 1.2em;
    }

    .flag-icon {
        width: 25px;
    }
}

@media (max-width: 480px) {
    .contact-heading {
        font-size: 1.2em;
    }

    .contact-item {
        font-size: 1em;
        padding: 0.5rem;
    }

    .flag-icon {
        width: 20px;
    }
} 